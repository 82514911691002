/** @format */

import React from 'react';
import { Row, Col } from '@components';
import { Portal } from 'react-portal';
import arrowLeft from '@assets/icons/arrow-left.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RidershipTiers from './ridershipTiers';
import {
  Wrapper,
  TopRow,
  Heading,
  RedeemBtn,
  TextSmall,
  TextStats,
  LevelBonus,
  TextRedeem,
  BackIcon,
  RedeemModal,
} from './styled';
import RedeemBonusModal from './redeemBonusModal';

const RidershipBonus = ({
  tiers,
  redeemAmount,
  redeemColor,
  levelBonus,
  levelBonusColor,
  goBack,
  onRedeem,
  redeemDateText,
  redeemTextColor,
  largeScreen,
  isRedeemModalOpen,
  toggleRedeemModal,
  isRedeemEnabled,
  giftCardType,
  handleGiftCardTypeClick,
  emailAddress,
  handleEmailChange,
  confirmEmailAddress,
  handleConfirmEmailChange,
  handleConfirmClick,
  handleCancelClick,
  redeemingRidershipBonusLoading,
  validate,
  errors,
  matchEmail,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper largeScreen={largeScreen}>
      <TopRow
        justify="space-between"
        align="center"
        spacingV={10}
        spacingH={20}
        largeScreen={largeScreen}
      >
        <Heading>{t('ridership-bonus.heading')}</Heading>
        <RedeemBtn
          id="redeem-btn"
          data-cy="redeem-btn"
          label={`${t('ridership-bonus.redeem')} $${redeemAmount || 0}`}
          color={redeemTextColor}
          bgColor={redeemColor}
          size="md"
          inverted
          onClick={onRedeem}
          disabled={!isRedeemEnabled}
        />
      </TopRow>
      <Row justify="space-between" align="flex-start" spacingV={10}>
        <BackIcon onClick={goBack} width={8} icon={arrowLeft} />
        <TextSmall
          id="back-btn"
          data-cy="back-btn"
          onClick={goBack}
          margin="10px 0 0 5px"
          bold
          opacity={0.9}
          cursor
        >
          {t('ridership-bonus.back')}
        </TextSmall>
        <Col justify="flex-start" align="flex-end">
          <LevelBonus
            justify="flex-start"
            align="flex-end"
            color={levelBonusColor}
          >
            <TextSmall margin="0 10px 0 0">
              {t('ridership-bonus.level-bonus')}
            </TextSmall>
            <TextStats margin="0 10px 0 0">
              {levelBonus ? `$${levelBonus}` : 'None'}
            </TextStats>
          </LevelBonus>
          <TextRedeem>
            {`${t('ridership-bonus.redeem-message')} ${redeemDateText}`}!
          </TextRedeem>
        </Col>
      </Row>
      <Row justify="flex-start" align="center" spacingV={10}>
        <RidershipTiers
          id="ridership-tiers"
          data-cy="ridership-tiers"
          tiers={tiers}
          largeScreen={largeScreen}
        />
      </Row>
      <Row justify="center" align="center">
        <TextSmall margin="0 10px 0 10px" alignCenter>
          {t('ridership-bonus.round-trip-message')}
        </TextSmall>
      </Row>
      <Row justify="center" align="center" spacingH={10}>
        <TextSmall bold alignCenter margin="0 0 20px 0">
          {t('ridership-bonus.bonus-message')}
        </TextSmall>
      </Row>
      {isRedeemModalOpen && (
        <Portal>
          <RedeemModal
            width={352}
            toggle={toggleRedeemModal}
          >
            <RedeemBonusModal
              giftCardType={giftCardType}
              handleGiftCardTypeClick={handleGiftCardTypeClick}
              emailAddress={emailAddress}
              handleEmailChange={handleEmailChange}
              confirmEmailAddress={confirmEmailAddress}
              handleConfirmEmailChange={handleConfirmEmailChange}
              handleConfirmClick={handleConfirmClick}
              handleCancelClick={handleCancelClick}
              redeemingRidershipBonusLoading={redeemingRidershipBonusLoading}
              validate={validate}
              errors={errors}
              matchEmail={matchEmail}
            />
          </RedeemModal>
        </Portal>
      )}
    </Wrapper>
  );
};

RidershipBonus.propTypes = {
  goBack: PropTypes.func.isRequired,
  tiers: PropTypes.array.isRequired,
  redeemAmount: PropTypes.number.isRequired,
  redeemColor: PropTypes.string.isRequired,
  levelBonus: PropTypes.number.isRequired,
  levelBonusColor: PropTypes.string.isRequired,
  onRedeem: PropTypes.func.isRequired,
  redeemDateText: PropTypes.string.isRequired,
  redeemTextColor: PropTypes.string.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  isRedeemModalOpen: PropTypes.bool.isRequired,
  toggleRedeemModal: PropTypes.func.isRequired,
  isRedeemEnabled: PropTypes.bool.isRequired,
  giftCardType: PropTypes.string.isRequired,
  handleGiftCardTypeClick: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  confirmEmailAddress: PropTypes.string.isRequired,
  handleConfirmEmailChange: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  redeemingRidershipBonusLoading: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  matchEmail: PropTypes.func.isRequired,
};

export default RidershipBonus;
