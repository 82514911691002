/** @format */

import React, { useRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DateTimePicker, Row, Row as SwitchRow, Switch } from '@components';
import { useLocalStorage, useSessionStorage } from '@hooks';
import moment from 'moment';
import { theme, hints, generals as generalConstants } from '@constants';
import { tripHelpers } from '@utils';
import {
  FormRow,
  FormCol,
  AddressInputRow,
  AddressInput,
  DateInput,
  TimeInput,
  FindRidesBtn,
  ValidTimesDropdownWrapper,
  ValidTimesDropdown,
  Tooltip,
} from './styled';

const Form = ({
  state,
  getPickupAddresses,
  getDestinationAddresses,
  clearAddresses,
  disabled,
  onChange,
  onIsRoundTripChange,
  onPickupAddressClick,
  onDestinationAddressClick,
  onSubmit,
  onSelectTripDate,
  onSelectTripTime,
  isValidDate,
  getValidTimes,
  loggedIn,
  onClear,
  checkArriveByPlace,
  pushToLogin,
  onOpenPicker,
  language,
}) => {
  const { t } = useTranslation();
  const pickupAddressRef = useRef(null);
  const departDateInputRef = useRef(null);
  const departTimeInputRef = useRef(null);

  const destinationAddressRef = useRef(null);

  const isReturnSwitchRef = useRef(null);
  const returnDateInputRef = useRef(null);
  const returnTimeInputRef = useRef(null);
  const [pickupOpen, setPickupOpen] = useState(false);
  const [dropoffOpen, setDropoffOpen] = useState(false);

  const submitBtnRef = useRef(null);
  const [seenHints, setSeenHints] = useLocalStorage('seenHints');
  const [, , removeTripOptionsMeta] = useSessionStorage('tripTypeFilters');

  const hintsVisible = useMemo(() => {
    return !seenHints.tripRequestLarge && loggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (!seenHints.tripRequestLarge && loggedIn) {
      setSeenHints({ ...setSeenHints, tripRequestLarge: true });
    }

    if (hintsVisible) {
      Tooltip.show(pickupAddressRef.current);
      setPickupOpen(true);
    }

    return () => {
      Tooltip.hide();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintsVisible, loggedIn]);

  const { query, loading, pickupAddresses, destinationAddresses } = state;

  const isSearchLoading =
    loading === generalConstants.SEARCH_TRIP_REQUEST_LOADING;

  const setMarginTop = marginTop => {
    const ele = document.getElementById('trip-request-form-tooltip');
    if (ele) {
      ele.style.marginTop = marginTop;
    }
  };

  const onSelectDepartDate = date => {
    onSelectTripDate('depart', date);

    if (hintsVisible) {
      Tooltip.hide(departDateInputRef.current);
      setMarginTop('39px');
      Tooltip.show(departTimeInputRef.current);
    }
  };

  const onSelectDepartTime = time => {
    onSelectTripTime(
      'depart',
      tripHelpers
        .synchronizeMinsAndHours(time, query.departTime)
        .format('HH:mm:ss'),
      true,
    );

    if (hintsVisible) {
      Tooltip.hide(departTimeInputRef.current);
      setMarginTop('39px');
      Tooltip.show(returnDateInputRef.current);
    }
  };

  const onSelectDepartTimeFromDropdown = selected => {
    onSelectTripTime('depart', selected.value);

    if (hintsVisible) {
      Tooltip.hide(departTimeInputRef.current);
      setMarginTop('39px');
      Tooltip.show(returnDateInputRef.current);
    }
  };

  const getInitialReturnTime = departTime => {
    if (!query || !query.departTime) {
      return null;
    }
    const format = 'YYYY-MM-DD HH:mm:ss';
    const time = moment(`${moment().format('YYYY-MM-DD HH')}:00:00`);
    const dTime = moment(`${moment().format('YYYY-MM-DD')} ${departTime}`);
    if (time.isAfter(dTime)) {
      return time.format(format);
    }

    return dTime.format(format);
  };

  const onSelectReturnDate = date => {
    onSelectTripDate('return', date);

    if (hintsVisible) {
      Tooltip.hide(returnDateInputRef.current);
      setMarginTop('39px');
      Tooltip.show(returnTimeInputRef.current);
    }
  };

  const onSelectReturnTime = time => {
    onSelectTripTime(
      'return',
      tripHelpers
        .synchronizeMinsAndHours(time, query.returnTime)
        .format('HH:mm:ss'),
      true,
    );

    if (hintsVisible) {
      Tooltip.hide(returnTimeInputRef.current);
      setMarginTop('39px');
      Tooltip.show(submitBtnRef.current);
    }
  };

  const onSelectReturnTimeFromDropdown = selected => {
    onSelectTripTime('return', selected.value);

    if (hintsVisible) {
      Tooltip.hide(returnTimeInputRef.current);
      setMarginTop('39px');
      Tooltip.show(submitBtnRef.current);
    }
  };

  const handlePickupInputFocus = () => {
    getPickupAddresses();
    if (hintsVisible) {
      if (pickupOpen) {
        setPickupOpen(false);
        return;
      }
      Tooltip.hide();
    }
  };

  const handleDropoffInputFocus = () => {
    getDestinationAddresses();
    if (hintsVisible) {
      if (dropoffOpen) {
        setDropoffOpen(false);
        return;
      }
      Tooltip.hide();
    }
  };

  const isValidDepartDate = current => isValidDate(current, 'depart');
  const isValidReturnDate = current => isValidDate(current, 'return');

  const validDepartTimes = getValidTimes('depart', query.departDate);
  const validReturnTimes = getValidTimes('return', query.returnDate);

  const handlePickupAddressClick = i => {
    onPickupAddressClick(i);

    if (hintsVisible) {
      Tooltip.hide(pickupAddressRef.current);
      Tooltip.show(destinationAddressRef.current);
      setDropoffOpen(true);
      setMarginTop('');
    }
  };

  const handleDestinationAddressClick = i => {
    onDestinationAddressClick(i);

    if (hintsVisible) {
      Tooltip.hide(destinationAddressRef.current);
      setMarginTop('');
      Tooltip.show(isReturnSwitchRef.current);
    }
  };

  const handleIsRoundTripChange = checked => {
    onIsRoundTripChange(checked);

    if (hintsVisible) {
      Tooltip.hide(isReturnSwitchRef.current);
      setMarginTop('39px');
      Tooltip.show(departDateInputRef.current);
    }
  };

  const handleSubmit = () => {
    onSubmit();

    if (hintsVisible) {
      Tooltip.hide(submitBtnRef.current);
    }
    removeTripOptionsMeta();
  };

  const renderDepartDateInput = (_inputProps, openCalender) => {
    return (
      <DateInput
        data-cy="depart-date-input"
        id="depart-date-input"
        inputRef={departDateInputRef}
        data-tip={t(hints.tripRequestLargeHints.departDate)}
        data-event="custom"
        data-for="trip-request-form-tooltip"
        name="depart"
        value={query.departDate}
        label={t('trip-request.large-screen.fields.depart-date.placeholder')}
        onFocus={openCalender}
        color={theme.BASE_COLOR}
        inverted
        readOnly
        autoComplete="off"
      />
    );
  };

  const renderReturnDateInput = (inputProps, openCalender) => {
    return (
      <DateInput
        data-cy="return-date-input"
        id="return-date-input"
        inputRef={returnDateInputRef}
        data-tip={t(hints.tripRequestLargeHints.returnDate)}
        data-event="custom"
        data-for="trip-request-form-tooltip"
        name="return"
        value={query.returnDate}
        label={t('trip-request.large-screen.fields.depart-date.placeholder')}
        onFocus={openCalender}
        color={theme.BASE_COLOR}
        inverted
        readOnly
        autoComplete="off"
      />
    );
  };

  const renderDepartTimeInput = (_inputProps, openCalender) => {
    return (
      <TimeInput
        data-cy="depart-time-input"
        id="depart-time-input"
        inputRef={departTimeInputRef}
        data-tip={t(hints.tripRequestLargeHints.departTime)}
        data-event="custom"
        data-for="trip-request-form-tooltip"
        name="depart"
        value={query.departTime}
        label={
          _inputProps?.placeholder ||
          t('trip-request.large-screen.fields.depart-time.pick-up')
        }
        color={theme.BASE_COLOR}
        inverted
        readOnly
        onFocus={openCalender}
        autoComplete="off"
      />
    );
  };

  const renderReturnTimeInput = (inputProps, openCalender) => {
    return (
      <TimeInput
        data-cy="return-time-input"
        id="return-time-input"
        inputRef={returnTimeInputRef}
        data-tip={t(hints.tripRequestLargeHints.returnTime)}
        data-event="custom"
        data-for="trip-request-form-tooltip"
        name="return"
        value={query.returnTime}
        label={
          inputProps?.placeholder ||
          t('trip-request.large-screen.fields.return-time.pick-up')
        }
        color={theme.BASE_COLOR}
        inverted
        readOnly
        onFocus={openCalender}
        autoComplete="off"
      />
    );
  };

  const onOpenDepartPicker = () => {
    onOpenPicker('depart');
  };

  const onOpenReturnPicker = () => {
    onOpenPicker('return');
  };

  return (
    <FormRow
      data-cy="trip-request-form-large"
      justify="flex-start"
      align="flex-start"
    >
      {hintsVisible && (
        <Tooltip
          id="trip-request-form-tooltip"
          backgroundColor={theme.WEIRD_GREEN}
          textColor={theme.WHITE}
          isCapture
        />
      )}
      <FormCol justify="space-between" align="flex-start">
        <AddressInputRow
          flex="initial"
          justify="flex-start"
          align="flex-start"
          onClick={!loggedIn ? pushToLogin : () => {}}
        >
          <AddressInput
            data-cy="pickup-address-input"
            id="pickup-address-input"
            inputProps={{
              inputRef: pickupAddressRef,
              'data-tip': t(hints.tripRequestLargeHints.pickupAddress),
              'data-event': 'custom',
              'data-for': 'trip-request-form-tooltip',
              name: 'pickupAddress',
              onChange,
              onFocus: () => {
                handlePickupInputFocus();
              },
              value: query.pickupAddress.label || query.pickupAddress,
              label: t('trip-request.large-screen.fields.pickup.placeholder'),
              color: theme.BASE_COLOR,
              inverted: true,
              icon: faSearch,
              onClear: () => {
                onClear('pickupAddress');
              },
            }}
            suggestions={pickupAddresses}
            onSuggestionClick={handlePickupAddressClick}
            clearSuggestions={clearAddresses}
          />
        </AddressInputRow>

        <Row
          id="depart-time-row"
          flex="initial"
          spacingV={10}
          justify="flex-start"
          align="flex-start"
          onClick={!loggedIn ? pushToLogin : () => {}}
        >
          <DateTimePicker
            placeholder="Date"
            onChange={onSelectDepartDate}
            dateFormat="ddd, MMM DD"
            isValidDate={isValidDepartDate}
            renderInput={renderDepartDateInput}
          />

          {validDepartTimes ? (
            <ValidTimesDropdownWrapper
              ref={departTimeInputRef}
              data-tip={t(hints.tripRequestLargeHints.departTimeDropdown)}
              data-event="custom"
              data-for="trip-request-form-tooltip"
            >
              <ValidTimesDropdown
                data-cy="depart-time-dropdown"
                key={validDepartTimes.placeholder}
                className="valid-depart-times"
                options={validDepartTimes.values}
                onChange={onSelectDepartTimeFromDropdown}
                placeholder={validDepartTimes.placeholder}
                color={theme.BASE_COLOR}
                value={query.departTime}
                inverted
                rounded
                borderRadius={100}
              />
            </ValidTimesDropdownWrapper>
          ) : (
            <DateTimePicker
              placeholder={
                checkArriveByPlace('depart')
                  ? t('trip-request.large-screen.fields.depart-time.arrive-at')
                  : t('trip-request.large-screen.fields.depart-time.pick-up')
              }
              onChange={onSelectDepartTime}
              {...(state.query.departTime || !loggedIn
                ? {}
                : { onOpen: onOpenDepartPicker })}
              timeFormat="hh:mm A"
              renderInput={renderDepartTimeInput}
              value={query.departTime}
            />
          )}
        </Row>
      </FormCol>
      <FormCol justify="space-between" align="flex-start">
        <>
          <AddressInputRow
            flex="initial"
            justify="flex-start"
            align="flex-start"
            onClick={!loggedIn ? pushToLogin : () => {}}
          >
            <AddressInput
              data-cy="destination-address-input"
              id="destination-address-input"
              inputProps={{
                inputRef: destinationAddressRef,
                'data-tip': t(hints.tripRequestLargeHints.destinationAddress),
                'data-event': 'custom',
                'data-for': 'trip-request-form-tooltip',
                name: 'destinationAddress',
                onChange,
                onFocus: () => {
                  handleDropoffInputFocus();
                },
                value:
                  query.destinationAddress.label || query.destinationAddress,
                label: t(
                  'trip-request.large-screen.fields.destination.placeholder',
                ),
                color: theme.BASE_COLOR,
                inverted: true,
                icon: faSearch,
                onClear: () => {
                  onClear('destinationAddress');
                },
              }}
              suggestions={destinationAddresses}
              onSuggestionClick={handleDestinationAddressClick}
              clearSuggestions={clearAddresses}
            />
          </AddressInputRow>
          {query.isReturn && (
            <Row
              id="return-time-row"
              flex="initial"
              spacingV={10}
              justify="flex-start"
              align="flex-start"
            >
              <DateTimePicker
                placeholder={t(
                  'trip-request.large-screen.fields.return-date.placeholder',
                )}
                data-cy="depart-time"
                onChange={onSelectReturnDate}
                dateFormat="ddd, MMM DD"
                isValidDate={isValidReturnDate}
                renderInput={renderReturnDateInput}
              />

              {validReturnTimes ? (
                <ValidTimesDropdownWrapper
                  ref={returnTimeInputRef}
                  data-tip={t(hints.tripRequestLargeHints.returnTimeDropdown)}
                  data-event="custom"
                  data-for="trip-request-form-tooltip"
                >
                  <ValidTimesDropdown
                    data-cy="return-time-dropdown"
                    key={validReturnTimes.placeholder}
                    className="valid-return-times"
                    options={validReturnTimes.values}
                    onChange={onSelectReturnTimeFromDropdown}
                    placeholder={validReturnTimes.placeholder}
                    color={theme.BASE_COLOR}
                    value={query.returnTime}
                    inverted
                    rounded
                    borderRadius={100}
                  />
                </ValidTimesDropdownWrapper>
              ) : (
                <DateTimePicker
                  placeholder={
                    checkArriveByPlace('return')
                      ? t(
                          'trip-request.large-screen.fields.return-time.arrive-at',
                        )
                      : t(
                          'trip-request.large-screen.fields.return-time.pick-up',
                        )
                  }
                  onChange={onSelectReturnTime}
                  {...(state.query.returnTime
                    ? {}
                    : { onOpen: onOpenReturnPicker })}
                  initialValue={getInitialReturnTime(query.departTime)}
                  timeFormat="hh:mm A"
                  renderInput={renderReturnTimeInput}
                  value={query.returnTime}
                />
              )}
            </Row>
          )}
        </>
      </FormCol>
      <FormCol justify="space-between" align="flex-start">
        <SwitchRow flex="initial" justify="flex-start" align="flex-start">
          <Switch
            data-cy="is-round-trip-switch"
            switchRef={isReturnSwitchRef}
            data-tip={t(hints.tripRequestLargeHints.isReturnToggle)}
            data-event="custom"
            data-for="trip-request-form-tooltip"
            id="is-round-trip-switch"
            checked={query.isReturn}
            onChange={loggedIn ? handleIsRoundTripChange : pushToLogin}
            unCheckedText={t(
              'trip-request.large-screen.fields.switch.labels.one-way',
            )}
            checkedText={t(
              'trip-request.large-screen.fields.switch.labels.round-trip',
            )}
            width={language === 'ru' ? 200 : 180}
            height={38}
            bgColor={theme.LIGHT_GREY}
            triggerColor={theme.WEIRD_GREEN}
            controlFontSize={language === 'ru'}
          />
        </SwitchRow>
        <Row
          spacingV={10}
          flex="initial"
          justify="flex-start"
          align="flex-start"
        >
          <FindRidesBtn
            data-cy="find-rides-btn"
            btnRef={submitBtnRef}
            data-tip={t(hints.tripRequestLargeHints.submitBtn)}
            data-event="custom"
            data-for="trip-request-form-tooltip"
            id="trip-request-submit-btn"
            onClick={handleSubmit}
            label={t('trip-request.large-screen.buttons.find-rides.label')}
            color={theme.WEIRD_GREEN}
            icon={isSearchLoading ? faSpinner : null}
            iconProps={{ spin: true }}
            disabled={disabled || isSearchLoading || !loggedIn}
          />
        </Row>
      </FormCol>
    </FormRow>
  );
};

Form.propTypes = {
  state: PropTypes.shape({
    query: PropTypes.shape({
      orgId: PropTypes.number,
      departDate: PropTypes.string,
      departTime: PropTypes.string,
      pickupAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      returnDate: PropTypes.string,
      returnTime: PropTypes.string,
      destinationAddress: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        PropTypes.string,
      ]),
      isReturn: PropTypes.bool,
    }).isRequired,
    pickupAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ).isRequired,
    destinationAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ).isRequired,
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  getPickupAddresses: PropTypes.func.isRequired,
  getDestinationAddresses: PropTypes.func.isRequired,
  clearAddresses: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onIsRoundTripChange: PropTypes.func.isRequired,
  onPickupAddressClick: PropTypes.func.isRequired,
  onDestinationAddressClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelectTripDate: PropTypes.func.isRequired,
  onSelectTripTime: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired,
  pushToLogin: PropTypes.func.isRequired,
  checkArriveByPlace: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  onOpenPicker: PropTypes.func,
};

export default Form;
