export const getDecoratedTiers = tiers => {
  const decoratedTiers = [];
  (tiers || []).forEach((tier, index) => {
    const totalRoundTripsRequired =
      index === 0
        ? tier.roundTripsRequired
        : decoratedTiers[index - 1].totalRoundTripsRequired + tier.roundTripsRequired;
    const totalTierbonus =
      index === 0
        ? tier.roundTripsBonus
        : decoratedTiers[index - 1].totalTierbonus + tier.roundTripsBonus;
    decoratedTiers.push({
      ...tier,
      totalRoundTripsRequired,
      totalTierbonus,
    });
  });
  return decoratedTiers.reverse();
};
