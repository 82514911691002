/* eslint-disable no-restricted-syntax */
/** @format */

import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { leaflet as leafletService } from '@services';
import { api, analytics, generals as generalConstants, localStorageKeys } from '@constants';
import { api as apiClient, toast, tripHelpers } from '@utils';
import { general as generalHelpers, place as placeHelpers } from '@utils/helpers';
import { setUser, trackAbEvent, trackEvent } from '@slices/app';
import { getTranslatedStringFromKey } from '@i18Loader';
import { cancelTripById } from '../myTrips';

const defaultQueryState = {
  departDate: '',
  departTime: '',
  pickupAddress: '',
  returnDate: '',
  returnTime: '',
  destinationAddress: '',
  isReturn: true,
  isCustomTime: false,
};

export const defaultState = {
  selectedTrip: {
    share: {
      return: null,
      depart: null,
    },
    transit: null,
    transitShare: {
      depart: null,
      return: null,
    },
  },
  selectedTripsPoints: null,
  estShiftPickupTime: [],
  selectedBookingMethod: null,
  selectedSelfPaymentOption: null,
  trips: {
    share: {
      depart: [],
      return: [],
    },
    transit: {
      depart: [],
      return: [],
    },
    transitShare: {
      depart: [],
      return: [],
    },
  },
  search: {
    query: defaultQueryState,
    pickupAddresses: [],
    destinationAddresses: [],
    tripTypeIndex: 0,
  },
  cachedSearchQuery: defaultQueryState,
  repeat: {
    count: { depart: 0, return: 0 },
    enabled: false,
    days: {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    },
    end: null,
  },
  promoCode: { code: '', applied: false },
  extraSeats: {
    ambulatory: 0,
    handicapped: 0,
  },
  loading: null,
  selectedTripsPrice: null,
  hideTripOptions: false,
};

const TripRequestSlice = createSlice({
  name: 'tripRequest',
  initialState: defaultState,
  reducers: {
    setLoading(state, action) {
      const { payload: loading } = action;

      return {
        ...state,
        loading,
      };
    },
    setExtraSeats(state, action) {
      const { payload: seats } = action;
      return {
        ...state,
        extraSeats: {
          ...state.extraSeats,
          [seats.key]: seats.value,
        },
      };
    },
    setSelectedTripsPrice(state, action) {
      const { payload } = action;

      return {
        ...state,
        selectedTripsPrice: payload,
      };
    },

    setSelectedBookingMethod(state, action) {
      const { payload } = action;

      return {
        ...state,
        selectedBookingMethod: payload,
      };
    },

    setSelectedSelfPaymentOption(state, action) {
      const { payload } = action;

      return {
        ...state,
        selectedSelfPaymentOption: payload,
      };
    },

    setSelectedTripsPoints(state, action) {
      const { selectedTripsPoints } = action.payload;
      return {
        ...state,
        selectedTripsPoints,
      };
    },

    setEstimatedShiftPickupTime(state, action) {
      const { estShiftPickupTime } = action.payload;
      return {
        ...state,
        estShiftPickupTime,
      };
    },

    setSearchQuery(state, action) {
      const { payload } = action;

      const resetDateTime =
        payload.key === 'pickupAddress' || payload.key === 'destinationAddress';

      const query = {
        ...state.search.query,
        departDate: resetDateTime ? '' : state.search.query.departDate,
        departTime: resetDateTime ? '' : state.search.query.departTime,
        returnDate: resetDateTime ? '' : state.search.query.returnDate,
        returnTime: resetDateTime ? '' : state.search.query.returnTime,
        [payload.key]: payload.value,
      };

      return {
        ...state,
        search: {
          ...state.search,
          query,
        },
      };
    },
    setCachedSearchQuery(state) {
      return {
        ...state,
        cachedSearchQuery: state.search.query,
      };
    },
    setSearchQueryTripDate(state, action) {
      const { payload } = action;
      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            [`${payload.type}Date`]: payload.data.tripDate,
          },
        },
      };
    },
    setSearchQueryTripTime(state, action) {
      const { payload } = action;
      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            [`${payload.type}Time`]: payload.data.tripTime,
          },
        },
      };
    },
    setSearchQueryCustomTime(state, action) {
      const { payload } = action;
      return {
        ...state,
        search: {
          ...state.search,
          query: {
            ...state.search.query,
            isCustomTime: payload,
          },
        },
      };
    },
    setSearchAddresses(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          [payload.key]: payload.value,
        },
      };
    },
    setSearchTripTypeIndex(state, action) {
      const { payload } = action;

      return {
        ...state,
        search: {
          ...state.search,
          tripTypeIndex: payload,
        },
      };
    },
    setTrips(state, action) {
      const { payload: trips } = action;
      return {
        ...state,
        trips: {
          share: {
            depart: trips.share.departTimes,
            return: trips.share.returnTimes,
          },
          transit: {
            depart: trips.transit.departTimes,
            return: trips.transit.returnTimes,
          },
          transitShare: {
            depart: trips.transit_share.departTimes,
            return: trips.transit_share.returnTimes,
          },
        },
      };
    },
    setSelectedTrip(state, action) {
      const { index, key, type } = action.payload;

      const updatedState = state;

      /**
       * if key = share => toggle selected state, reset trasitShare
       * if key = transitShare => toggle selected state, reset share
       */
      if (key === 'share') {
        updatedState.selectedTrip.share[type] =
          updatedState.selectedTrip.share[type] !== index ? index : null;
        updatedState.selectedTrip.transitShare[type] = null;
      // eslint-disable-next-line no-constant-condition
      } else if (key === 'transitShare' || 'transit_share') {
        updatedState.selectedTrip.transitShare[type] = updatedState.selectedTrip.transitShare[type] !== index ? index : null;
        updatedState.selectedTrip.share[type] = null;
      }

      return updatedState;
    },
    setRepeatEnabled(state, action) {
      const { enabled, end } = action.payload;
      return {
        ...state,
        repeat: {
          ...defaultState.repeat,
          enabled,
          end,
        },
      };
    },
    setRepeatData(state, action) {
      const data = action.payload;

      return {
        ...state,
        repeat: {
          ...state.repeat,
          ...data,
        },
      };
    },
    setPromoCode(state, action) {
      const promoCode = action.payload;
      return {
        ...state,
        promoCode,
      };
    },
    setHideTripOptions(state, action) {
      const { payload: hideTripOptions } = action;

      return {
        ...state,
        hideTripOptions,
      };
    },
    resetSearchAddresses(state) {
      return {
        ...state,
        search: {
          ...state.search,
          pickupAddresses: [],
          destinationAddresses: [],
        },
      };
    },
    resetSelectedTrip(state) {
      return {
        ...state,
        selectedTrip: defaultState.selectedTrip,
      };
    },
    resetAfterSearchTrips(state) {
      return {
        ...defaultState,
        search: state.search,
      };
    },
    resetTripRequestState() {
      return {
        ...defaultState,
      };
    },

    // any field in trip request which needs to be retained can be retained here on reset
    resetTripRequestStatePartially(state) {
      const { selectedTripsPrice, extraSeats } = state;
      return {
        ...defaultState,
        selectedTripsPrice,
        extraSeats,
      };
    },

    resetTripRequestStateOnTripOverviewUnmount(state) {
      return {
        ...state,
        repeat: defaultState.repeat,
        promoCode: { code: '', applied: false },
      };
    },
  },
});

export const {
  setLoading,
  setSelectedTripsPrice,
  setSearchQuery,
  setCachedSearchQuery,
  setSearchQueryTripDate,
  setSearchQueryTripTime,
  setSearchAddresses,
  setTrips,
  setExtraSeats,
  setSelectedTrip,
  setRepeatEnabled,
  setRepeatData,
  setPromoCode,
  resetSearchAddresses,
  resetTripRequestStatePartially,
  resetSelectedTrip,
  resetAfterSearchTrips,
  resetTripRequestState,
  resetTripRequestStateOnTripOverviewUnmount,
  setSearchQueryCustomTime,
  setSelectedTripsPoints,
  setEstimatedShiftPickupTime,
  setSelectedBookingMethod,
  setSelectedSelfPaymentOption,
  setHideTripOptions,
  setSearchTripTypeIndex,
} = TripRequestSlice.actions;

const getAddresses = async (
  query,
  userPlaces,
  busStops,
  organizationPlaces,
  leafServiceEnable,
  organization,
) => {
  let leafletAddresses = [];
  let filteredBusStops = [];
  let filteredUserPlaces = [];

  if (leafServiceEnable) {
    leafletAddresses = await leafletService.search(query);
    const {
      state,
      state_restricted: stateRestricted,
    } = organization.detail || {};
    if (state && stateRestricted) {
      leafletAddresses = leafletAddresses.filter(
        o =>
          o.label.match(
            ` ${generalConstants.STATE_NAMES_LONG_TO_SHORT[state]} `),
      );
    }

    filteredUserPlaces = generalHelpers
      .queryFilter(userPlaces, query, ['label', 'type'])
      .splice(0, 10)
      .map(userPlace => ({
        ...userPlace,
        lat: Number(userPlace.lat),
        lng: Number(userPlace.lng),
      }));

    filteredBusStops = generalHelpers
      .queryFilter(busStops, query, ['label', 'name'])
      .splice(0, 10)
      .map(busStop => ({
        ...busStop,
        lat: Number(busStop.lat),
        lng: Number(busStop.lng),
      }));
  }
  const filteredOrgPlaces = generalHelpers
    .queryFilter(organizationPlaces, query, ['label', 'name'])
    .splice(0, 20);

  return [
    ...filteredUserPlaces,
    ...filteredOrgPlaces,
    ...filteredBusStops,
    ...leafletAddresses,
  ];
};

export const fetchAddresses = (key, query) => {
  return async (dispatch, getState) => {
    const {
      user,
      busStopMarkers,
      organizationPlaces,
      organizations,
      selectedOrganization,
    } = getState().app;

    const organization =
      selectedOrganization !== null && Array.isArray(organizations) && organizations[selectedOrganization];

    const restrictToOrgLocations =
      organization?.organization?.restrictToOrgLocations ||
      generalConstants.RESTRICT_TO_ORG_LOCATIONS.No;

    const isRestrictToOrgLocations =
      restrictToOrgLocations === generalConstants.RESTRICT_TO_ORG_LOCATIONS.Yes;

    const userPlaces = !isRestrictToOrgLocations
      ? generalHelpers.getObjectProperty(user, 'detail.addresses', [])
      : [];

    const addresses = await getAddresses(
      query,
      userPlaces,
      busStopMarkers,
      organizationPlaces,
      !isRestrictToOrgLocations,
      organization,
    );

    const filteredOrgPlaces = generalHelpers
      .queryFilter(organizationPlaces, query, ['label', 'name'])
      .splice(0, 20);

    if (filteredOrgPlaces.length === 0) {
      dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.ORGANIZATION_PLACES_NOT_FOUND,
        subJourneyName: 'Book A Ride',
        journeyName: 'Rider Experience',
        details: {
          query,
          organizationPlaces,
          filteredOrgPlaces,
          organization,
        },
      }));
    }

    dispatch(
      setSearchAddresses({
        key: `${key}Addresses`,
        value: addresses,
      }),
    );
  };
};

const throwIfUserCanNotSearchTrips = (user, organizations) => {
  if (!user) {
    toast.show(
      'error',
      getTranslatedStringFromKey(
        'messages.error.authentication.login-required',
      ),
    );
  }

  if (!organizations) {
    toast.show(
      'error',
      getTranslatedStringFromKey(
        'messages.error.invites.no-organization-invite',
      ),
    );
  }
};

const getSearchTripRequestData = (query, organization) => {
  let departDateTime = '';
  let returnDateTime = '';
  departDateTime = `${query.departDate} ${query.departTime}`;

  if (query.isReturn) {
    returnDateTime = `${query.returnDate} ${query.returnTime}`;
  }

  const getLocation = loc => ({
    label: loc.label,
    lat: loc.lat,
    lng: loc.lng,
  });

  const data = {
    orgIds: [organization.id],
    departDateTime,
    pickup: getLocation(query.pickupAddress),
    destination: getLocation(query.destinationAddress),
    isReturn: query.isReturn,
  };

  if (query.isReturn) {
    data.returnDateTime = returnDateTime;
  }

  return data;
};

export const searchTrips = history => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.SEARCH_TRIP_REQUEST_LOADING));

    try {
      const {
        app: { user, organizations, selectedOrganization },
        tripRequest: {
          search: { query },
        },
      } = getState();

      throwIfUserCanNotSearchTrips(user, organizations);
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: 'Search For A Trip',
          subJourneyName: 'Book A Ride',
          journeyName: 'Rider Experience',
          elementId: 'find-rides-btn',
          isDataCy: true,
          details: {
            'pickup-location': query.pickupAddress.label,
            'destination-location': query.destinationAddress.label,
            'pickup-date': query.departDate,
            'pickup-time': query.departTime,
            'return-date': query.returnDate,
            'return-time': query.returnTime,
          },
        }),
      );
      const { organization } = (organizations || [])[selectedOrganization] || {};
      const data = getSearchTripRequestData(
        query,
        organization,
      );

      const response = await apiClient.post(api.endpoints.SEARCH_TRIPS, data);
      dispatch(resetAfterSearchTrips());
      dispatch(setCachedSearchQuery());
      dispatch(setTrips(response.data));
      const payload = {
        departDate: moment(data?.departDateTime).format('MM/DD/YYYY') || '',
        returnDate: moment(data?.returnDateTime).format('MM/DD/YYYY') || '',
      };
      const shouldRedirectToTripOverview = checkIfOnlyOneTripTypeAndSelect(
        organization,
        response.data,
        dispatch,
        payload,
      );
      dispatch(setHideTripOptions(shouldRedirectToTripOverview));
      if (shouldRedirectToTripOverview) {
        history.push('/trip-overview');
      } else {
        history.push('/trip-options');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SEARCH_TRIPS_API_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

export const workforceSearchTrips = (history, payload) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.SEARCH_TRIP_REQUEST_LOADING_WF));

    try {
      const {
        app: { user, organizations, selectedOrganization },
      } = getState();

      throwIfUserCanNotSearchTrips(user, organizations);

      const { organization } = (organizations || [])[selectedOrganization] || {};
      const data = {
        ...payload,
        isShiftBased: true,
        orgIds: [organization.id],
      };

      const response = await apiClient.post(api.endpoints.SEARCH_TRIPS, data);
      dispatch(resetAfterSearchTrips());
      dispatch(setCachedSearchQuery());
      dispatch(setTrips(response.data));
      const tripPayload = {
        departDate: payload.departDate,
        returnDate: payload.returnDate,
      };
      const shouldRedirectToTripOverview = checkIfOnlyOneTripTypeAndSelect(
        organization,
        response.data,
        dispatch,
        tripPayload,
      );
      dispatch(setHideTripOptions(shouldRedirectToTripOverview));
      if (shouldRedirectToTripOverview) {
        history.push('/trip-overview');
      } else {
        history.push('/trip-options');
      }
      dispatch(setLoading(null));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SEARCH_TRIPS_API_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

const checkIfOnlyOneTripTypeAndSelect = (
  organization,
  searchedTrips,
  dispatch,
  payload,
) => {
  let tripTypes = ['SHARE'];
  const { detail: orgDetails } = organization || {};
  const { tripRequestTypes, transit } = orgDetails || {};

  if (
    transit &&
    transit.enabled &&
    Array.isArray(tripRequestTypes) &&
    tripRequestTypes.length
  ) {
    tripTypes = tripRequestTypes;
  }

  if (tripTypes.length > 1 || tripTypes.includes('TRANSIT')) return false;
  const selectTrip = (trip, key, type) => {
    if (tripTypes.includes(key.toUpperCase())) {
      dispatch(setSelectedTrip({ index: 0, key, type }));
      return true;
    }
    return false;
  };
  for (const trip of Object.values(searchedTrips)) {
    const hasDepart = trip.departTimes && trip.departTimes.length === 1;
    const hasReturn = trip.returnTimes && trip.returnTimes.length === 1;
    if (hasDepart) {
      const timeZone = placeHelpers.getTimezoneFromPlace(trip.departTimes[0].places[0]);
      const arriveByDateTime = tripHelpers.convertTimeToGivenTimeZone(
        trip.departTimes[0].arriveTime,
        timeZone,
        'MM/DD/YYYY',
        'MM/DD/YYYY HH:mm:ss',
      );
      const departDateMatch = moment(payload.departDate).isSame(
        moment(arriveByDateTime).format('MM/DD/YYYY'),
      );
      if (!departDateMatch) {
        break;
      }
    }
    if (hasReturn) {
      const timeZone = placeHelpers.getTimezoneFromPlace(trip.returnTimes[0].places[0]);
      const leaveByDateTime = tripHelpers.convertTimeToGivenTimeZone(
        trip.returnTimes[0].leaveTime,
        timeZone,
        'MM/DD/YYYY',
        'MM/DD/YYYY HH:mm:ss',
      );
      const returnDateMatch = moment(payload.returnDate).isSame(
        moment(leaveByDateTime).format('MM/DD/YYYY'),
      );
      if (!returnDateMatch) {
        break;
      }
    }
    if (hasDepart && hasReturn) {
      if (
        selectTrip(trip.departTimes, trip.departTimes[0].type, 'depart') &&
        selectTrip(trip.returnTimes, trip.returnTimes[0].type, 'return')
      ) {
        return true;
      }
    } else if (hasDepart) {
      if (selectTrip(trip.departTimes, trip.departTimes[0].type, 'depart')) {
        return true;
      }
    } else if (hasReturn) {
      if (selectTrip(trip.returnTimes, trip.returnTimes[0].type, 'return')) {
        return true;
      }
    }
  }

  return false;
};

const formatDateForTripRequest = date =>
  moment(date, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const checkIfTripIsTransitShare = (type, selectedTrip) => {
  return selectedTrip.transitShare[type] !== null;
};

// will return transit or transitShare trip, based on selected value
const getSelectedTrip = (type, trips, selectedTrip) => {
  let trip = {};

  const shareSelected = selectedTrip.share[type];
  const transitShareSelected = selectedTrip.transitShare[type];

  if (shareSelected != null) {
    trip = trips.share[type][shareSelected];
  }

  if (transitShareSelected != null) {
    const selectedTransitShareTrip = trips.transitShare[type][transitShareSelected];
    trip = selectedTransitShareTrip.steps.find(step => step.type === 'share');
  }

  return trip;
};

const getArrivalTime = trip => {
  const arrivalTime =
    trip.requestType === 'Ready By' ? trip.leaveTime : trip.arriveTime;

  return formatDateForTripRequest(arrivalTime);
};

const getRepeatUntilTime = (trip, repeat) => {
  const repeatUntilTime = repeat.end ? repeat.end : trip.leaveTime;

  return formatDateForTripRequest(repeatUntilTime);
};

const getTripRequestData = (type, state) => {
  const {
    tripRequest: { selectedTrip, trips, repeat, extraSeats },
    app: {
      user: { id: userId },
    },
  } = state;

  const trip = getSelectedTrip(type, trips, selectedTrip);

  const arrivalTime = getArrivalTime(trip);
  const repeatUntilTime = getRepeatUntilTime(trip, repeat);
  const isDepart =
    selectedTrip.share?.depart !== null ||
    selectedTrip.transitShare?.depart !== null;
  const isReturn =
    selectedTrip.share?.return !== null ||
    selectedTrip.transitShare?.return !== null;
  const pickupPlace = (trip.places || []).find(p => p.type === 'Pick-up');
  const dropoffPlace = (trip.places || []).find(p => p.type === 'Drop-off');
  const tripRequestData = {
    status: 'Requested',
    organizationId: trip.organizationId,
    pickupOrgPlaceId: pickupPlace.orgPlaceId,
    dropoffOrgPlaceId: dropoffPlace.orgPlaceId,
    arrival: arrivalTime,
    roundTrip: false,
    users: [
      {
        userId,
      },
    ],
    createdBy: userId,
    places: [...trip.places],
    ...repeat.days,
    recurringType: repeat.enabled ? 'Weekly' : '',
    end: repeatUntilTime,
    type: trip.requestType,
    extraSeats,
    detail: {
      isRoundTrip: isReturn && isDepart,
    },
  };

  // if it is a transit_share trip, attach transitShareTripRequest object to detail key
  if (checkIfTripIsTransitShare(type, selectedTrip)) {
    const transitShareTripRequest =
      trips.transitShare[type][selectedTrip.transitShare[type]];

    tripRequestData.detail = {
      transitShareTripRequest: {
        ...transitShareTripRequest,
        tripType: type,
      },
    };
  }

  return tripRequestData;
};

const getCreateTripURL = (type, trips, selectedTrip, selectedBookingMethod) => {
  const trip = getSelectedTrip(type, trips, selectedTrip);

  if (trip.cost === 'FREE') {
    return api.endpoints.TRIP_REQUESTS;
  }

  if (selectedBookingMethod === generalConstants.BOOKING_METHODS.POINTS) {
    return api.endpoints.POINTS_TO_BOOK;
  }

  return api.endpoints.PAY_TO_BOOK;
};

const getPlacesData = data => {
  return data.places.map(async place => {
    if (
      typeof place.placeId === 'string' &&
      place.placeId.indexOf('osm') > -1
    ) {
      const placeData = {
        address: place.address,
        lat: place.lat,
        lng: place.lng,
      };
      const placesResponse = await apiClient
        .post(api.endpoints.PLACES, placeData)
        .then(res => res.data);
      return {
        type: place.type,
        placeId: placesResponse.id,
        address: placesResponse.address,
        lat: placesResponse.lat,
        lng: placesResponse.lng,
        timezone: placesResponse.timezone,
      };
    }
    return place;
  });
};

// api call for creating single trip request (depart or return)
const sendTripRequest = async (type, state, otherTripRequest) => {
  const {
    tripRequest: { selectedBookingMethod, selectedSelfPaymentOption },
  } = state;
  const tripRequestFormData = getTripRequestData(type, state);

  const placesData = getPlacesData(tripRequestFormData);
  tripRequestFormData.places = await Promise.all(placesData);
  const source = JSON.parse(localStorage.getItem(localStorageKeys.SOURCE));
  if (source && source.saved === false) {
    delete source.saved;
    tripRequestFormData.source = source;
  }
  const {
    tripRequest: { selectedTrip, trips, promoCode, extraSeats },
    paymentMethods: { selectedPaymentMethod, addedPaymentMethods, addedSquareCards },
    app: { organizations, selectedOrganization },
  } = state;
  let data = tripRequestFormData;
  const trip = getSelectedTrip(type, trips, selectedTrip);

  /*
    if its a return trip request, we also send id
    of the depart trip request
  */
  if (
    type === 'return' &&
    data?.detail?.isRoundTrip &&
    otherTripRequest?.departTripIds
  ) {
    data.departTripIds = otherTripRequest.departTripIds;
    data.isReturnRide = true;
  }

  if (trip.cost !== 'FREE') {
    if (selectedBookingMethod === generalConstants.BOOKING_METHODS.POINTS) {
      data = {
        type,
        tripRequest: { ...data },
        otherTripRequest,
      };
    } else if (selectedSelfPaymentOption === generalConstants.SELF_PAYMENT_OPTIONS.CASH) {
        data = {
          type,
          tripRequest: {
            ...data,
            detail: {
              ...data.detail,
              payment: {
                acceptCash: true,
              },
            },
          },
          promoCode: promoCode.applied ? promoCode.code : '',
          otherTripRequest,
          extraSeats,
        };
      } else {
        const paymentMethod =
          (organizations || [])[selectedOrganization]?.organization?.paymentOption === 'SQUAREUP' ?
          addedSquareCards[selectedPaymentMethod] : addedPaymentMethods[selectedPaymentMethod];
        data = {
          type,
          paymentMethodId: paymentMethod ? paymentMethod.id : '',
          tripRequest: { ...data },
          promoCode: promoCode.applied ? promoCode.code : '',
          otherTripRequest,
          extraSeats,
        };
      }
  }

  const url = getCreateTripURL(
    type,
    trips,
    selectedTrip,
    selectedBookingMethod,
  );
  const response = await apiClient.post(url, data);

  return response;
};

// thunk for creating trip requests (depart / return)
export const sendTripRequests = (tripData, confirmCallback) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(generalConstants.CREATE_TRIP_REQUEST_LOADING));

    const state = getState();
    const {
      tripRequest: {
        selectedTripsPrice,
        extraSeats,
        selectedBookingMethod,
        selectedTrip,
        hideTripOptions
      },
      app: {
        currentLocation,
      },
    } = state;

    let returnTripRequestExecuting = false;
    let departTripIds = null;

    const isDepartSelected =
      selectedTrip.share.depart !== null ||
      selectedTrip.transitShare.depart !== null;

    const isReturnSelected =
      selectedTrip.share.return !== null ||
      selectedTrip.transitShare.return !== null;
    try {
      const created = [];
      let tripRequestDepartResponse = null;
      let tripRequestReturnResponse = null;
      let userBalance = state.app.user.balance;
      if (isDepartSelected) {
        tripRequestDepartResponse = await sendTripRequest(
          'depart',
          state,
          tripData ? tripData.tripRequest.return : null,
        );

        dispatch(trackTripRequestBookingEvent(tripRequestDepartResponse.data, currentLocation, tripData?.tripRequest?.depart?.places?.find(place => place.type === 'Pick-up')));

        created.push(tripRequestDepartResponse.data);
        if (tripRequestDepartResponse.data?.tripRequests[0]?.detail.source) {
          const source = JSON.parse(localStorage.getItem(localStorageKeys.SOURCE));
          source.saved = true;
          localStorage.setItem(localStorageKeys.SOURCE, JSON.stringify(source));
        }

        const departTrips = tripRequestDepartResponse?.data?.tripRequests;
        if (Array.isArray(departTrips)) {
          departTrips.forEach(trip => {
            if (trip?.detail?.payment?.pricing?.credits) {
              userBalance -= parseFloat(trip.detail.payment.pricing.credits);
            }
          });
        }
      }

      if (isReturnSelected) {
        departTripIds = (
          tripRequestDepartResponse?.data?.tripRequests || []
        ).map(trip => trip.id);

        returnTripRequestExecuting = true;
        tripRequestReturnResponse = await sendTripRequest(
          'return',
          state,
          (tripData && departTripIds.length)
            ? {
                ...tripData.tripRequest.depart,
                departTripIds,
              }
            : null,
        );

        dispatch(trackTripRequestBookingEvent(tripRequestReturnResponse.data, currentLocation, tripData?.tripRequest?.return?.places?.find(place => place.type === 'Pick-up')));
        created.push(tripRequestReturnResponse.data);
        const returnTrips = tripRequestReturnResponse?.data?.tripRequests;
        if (Array.isArray(returnTrips)) {
          returnTrips.forEach(trip => {
            if (trip?.detail?.payment?.pricing?.credits) {
              userBalance -= parseFloat(trip.detail.payment.pricing.credits);
            }
          });
        }
      }

      dispatch(setLoading(null));

      if (selectedBookingMethod === generalConstants.BOOKING_METHODS.POINTS) {
        const trip = tripRequestDepartResponse || tripRequestReturnResponse;
        const points =
          trip.data?.tripRequests[0]?.detail.points;
        dispatch(
          setUser({ ...state.app.user, points: points.remainingPoints }),
        );
        localStorage.setItem('user', JSON.stringify(state.app.user));
        toast.show(
          'success',
          getTranslatedStringFromKey(
            'confirmation-modals.booked.points.text',
            points.totalPoints,
          ),
        );
      } else if (tripData) {
        dispatch(setUser({ ...state.app.user, balance: userBalance }));
        localStorage.setItem('user', JSON.stringify({ ...state.app.user, balance: userBalance }));
      }

      confirmCallback(created, selectedTripsPrice, extraSeats, () => {
        dispatch(resetTripRequestStatePartially(true));
      });
     if (hideTripOptions) dispatch(setHideTripOptions(false));
    } catch (error) {
      if (returnTripRequestExecuting && departTripIds?.length) {
        const cancelRepeatingRequests = departTripIds.length > 1;
        dispatch(
          cancelTripById(
            departTripIds[0],
            false,
            cancelRepeatingRequests,
          ),
        );
      }
      // eslint-disable-next-line no-console
      console.log('SEND_TRIP_REQUESTS_API_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

const trackTripRequestBookingEvent = (trips, currentLocation, pickupLocation) => {
  return async (dispatch) => {
    let event = analytics.BOOK_THIS_RIDES_BUTTON;
    const properties = {};
    const { tripRequests } = trips;

    if (Array.isArray(trips.tripRequests) && trips.tripRequests.length > 1) {
      event = analytics.RECURRING_RIDE_BOOKED;
      properties.bookingStart = tripRequests[0].arrival;
      properties.bookingEnd = tripRequests[tripRequests.length - 1].arrival;
      properties.tripRequests = [tripRequests[0]];
    } else if (trips.tripRequests.length === 1) {
      properties.tripRequests = [tripRequests[0]];
    } else if (!Array.isArray(trips.tripRequests)) {
      properties.tripRequests = [tripRequests];
    }

    if (properties.tripRequests[0].detail.path) {
      delete properties.tripRequests[0].detail.path;
    }

    const tripData = { ...trips };
    delete tripData.tripRequests;

    dispatch( // For Activity Feed
      trackEvent(
        event,
        { ...tripData, ...properties },
      ),
    );

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: event,
        subJourneyName: 'Book A Ride',
        journeyName: 'Rider Experience',
        details: { ...tripData, ...properties },
      }),
    );
    const dataToBeLogged = {};
    let bookingDuration = null;
    if (properties.tripRequests[0]) {
      bookingDuration = moment.duration(
        moment(properties.tripRequests[0].arrival).diff(
          moment(properties.tripRequests[0].createdAt),
        ),
      );
      dataToBeLogged['is-round-trip'] = properties.tripRequests[0].detail?.isRoundTrip;
      dataToBeLogged['organization-id'] = properties.tripRequests[0].organizationId;
      dataToBeLogged['recurring-type'] = properties.tripRequests[0].recurringType;
      dataToBeLogged['is-handicapped'] = properties.tripRequests[0].handicapped;
      dataToBeLogged.fare = properties.tripRequests[0].fare;
      dataToBeLogged.bookings = properties.tripRequests[0].bookings;
      dataToBeLogged.total = properties.tripRequests[0].total;
      dataToBeLogged.tip = properties.tripRequests[0].tip;
      dataToBeLogged['estimated-miles'] = properties.tripRequests[0].estimatedMiles;
    }
    let distanceFromPickup = null;
    if (pickupLocation && currentLocation) {
      distanceFromPickup = placeHelpers.getDistance(pickupLocation, {
        lat: currentLocation[0],
        lng: currentLocation[1],
      });
      distanceFromPickup = distanceFromPickup ? distanceFromPickup.toFixed(2) : null;
    }
    if (bookingDuration) {
      bookingDuration = `${bookingDuration.days()}d ${bookingDuration.hours()}h ${bookingDuration.minutes()}m`;
    }

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Book A Trip',
        subJourneyName: 'Book A Ride',
        journeyName: 'Rider Experience',
        elementId: 'book-this-ride',
        isDataCy: true,
        details: {
          'distance-from-pickup-miles': distanceFromPickup,
          'booking-duration': bookingDuration,
          ...dataToBeLogged,
        },
      }),
    );
  };
};

const getSelectedTripPricing = async (data, dispatch, state) => {
  const {
    tripRequest: { promoCode: promoInStore },
  } = state;

  const { data: selectedTripsPrice } = await apiClient.post(
    api.endpoints.GET_TRIP_REQUEST_PRICING,
    data,
  );

  if (data.promoCode) {
    dispatch(
      setPromoCode({
        ...promoInStore,
        applied: true,
      }),
    );
  }

  dispatch(
    setSelectedTripsPrice({
      ...selectedTripsPrice.totalPrice,
      tripData: data,
    }),
  );
};

const getSelectedTripPoints = async (data, dispatch) => {
  const { data: selectedTripsPoints } = await apiClient.post(
    api.endpoints.GET_TRIP_POINTS,
    data,
  );

  dispatch(
    setSelectedTripsPoints({
      selectedTripsPoints,
    }),
  );
};
export const getEstimatedShiftPickupTime = pickup => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      app: { organizations, selectedOrganization, organizationPlaces },
    } = state;

    try {
      const { workplaceId } = organizations[selectedOrganization];
      const payload = {
        pickup,
        workplace: workplaceId,
      };

      dispatch(setLoading(generalConstants.EST_SHIFT_PICKUP_TIME_LOADING));
      const dataIdentifier = `${pickup}-${workplaceId}`;
      const shiftIdentifier = `${workplaceId}-shifts`;
      const { shifts } =
        organizationPlaces?.find(place => place.orgPlaceId === workplaceId) ||
        {};

      let cachedEstPickupForShifts =
        JSON.parse(localStorage.getItem(localStorageKeys.CACHE_EST_PICKUPS)) ||
        {};

      if (
        placeHelpers.resetEstPickupCache(
          shifts,
          cachedEstPickupForShifts[shiftIdentifier],
        )
      ) {
        localStorage.setItem(
          localStorageKeys.CACHE_EST_PICKUPS,
          JSON.stringify({}),
        );
        cachedEstPickupForShifts = {};
      }

      if (cachedEstPickupForShifts[dataIdentifier]) {
        dispatch(
          setEstimatedShiftPickupTime({
            estShiftPickupTime: cachedEstPickupForShifts[dataIdentifier],
          }),
        );
      } else {
        const { data: estShiftPickupTime } = await apiClient.post(
          api.endpoints.GET_SHIFTS_EST_PICKUP_TIME,
          payload,
        );
        dispatch(setEstimatedShiftPickupTime({ estShiftPickupTime }));
        localStorage.setItem(
          localStorageKeys.CACHE_EST_PICKUPS,
          JSON.stringify({
            ...cachedEstPickupForShifts,
            [dataIdentifier]: estShiftPickupTime,
            [`${workplaceId}-shifts`]: shifts,
          }),
        );
      }

      dispatch(setLoading(null));
    } catch (error) {
      dispatch(setEstimatedShiftPickupTime({ estShiftPickupTime: null }));
      dispatch(setLoading(null));
      // eslint-disable-next-line no-console
      console.log('GET_SHIFT_EST_PICKUP_TIME_ERROR', error);
    }
  };
};

export const getSelectedTripsPicingAndPoints = promo => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      app: { organizations, selectedOrganization },
      tripRequest: {
        cachedSearchQuery: { isReturn },
        promoCode: promoInStore,
        selectedTrip,
        selectedSelfPaymentOption,
      },
    } = state;

    const promoCode = promoInStore.applied ? promoInStore.code : promo;
    const acceptCash = selectedSelfPaymentOption === generalConstants.SELF_PAYMENT_OPTIONS.CASH;
    const orgBookingMethods = (organizations || [])[selectedOrganization]?.organization?.bookingMethods ||
                (organizations || [])[selectedOrganization]?.organization?.detail?.bookingMethods || [];
    const bookingMethods = orgBookingMethods.filter(
      x => x !== generalConstants.BOOKING_METHODS.ORGANIZATION_PAID,
    );

    if (bookingMethods.length === 0) {
      bookingMethods.push(generalConstants.BOOKING_METHODS.SELF_PAID);
    }

    const isSelfPaid = bookingMethods.includes(
      generalConstants.BOOKING_METHODS.SELF_PAID,
    );
    const isPointsPaid = bookingMethods.includes(
      generalConstants.BOOKING_METHODS.POINTS,
    );

    if (!isSelfPaid && !isPointsPaid) {
      return;
    }

    try {
      dispatch(setLoading(true));
      let tripRequestDepartData = null;
      let placesData = null;
      if (selectedTrip.share?.depart !== null || selectedTrip.transitShare?.depart !== null) {
        tripRequestDepartData = await getTripRequestData('depart', state);
        placesData = getPlacesData(tripRequestDepartData);
        tripRequestDepartData.places = await Promise.all(placesData);
      }
      let tripRequestReturnData = null;

      if (
        isReturn &&
        (selectedTrip.share?.return !== null ||
          selectedTrip.transitShare?.return !== null)
      ) {
        tripRequestReturnData = await getTripRequestData('return', state);
        placesData = getPlacesData(tripRequestReturnData);
        tripRequestReturnData.places = await Promise.all(placesData);
      }

      const data = {
        tripRequest: {},
        promoCode,
        acceptCash,
      };

      if (tripRequestDepartData) {
        data.tripRequest.depart = tripRequestDepartData;
      }

      if (tripRequestReturnData) {
        data.tripRequest.return = tripRequestReturnData;
      }

      const dataToBeLogged = {};
      dataToBeLogged['booking-method'] = selectedSelfPaymentOption;
      dataToBeLogged['self-paid'] = isSelfPaid || null;
      dataToBeLogged['points-paid'] = isPointsPaid || null;
      if (data.tripRequest.depart) {
        dataToBeLogged['is-round-trip'] = data.tripRequest.depart.isRoundTrip;
        dataToBeLogged['organization-id'] = data.tripRequest.depart.organizationId;
        dataToBeLogged['depart-recurring-type'] = data.tripRequest.depart.recurringType;
        dataToBeLogged['depart-extra-seats-handicapped'] = data.tripRequest.depart.extraSeats.handicapped;
        dataToBeLogged['depart-extra-seats-ambulatory'] = data.tripRequest.depart.extraSeats.ambulatory;
      }
      if (data.tripRequest.return) {
        dataToBeLogged['is-round-trip'] = data.tripRequest.return.detail.isRoundTrip;
        dataToBeLogged['organization-id'] = data.tripRequest.return.organizationId;
        dataToBeLogged['return-recurring-type'] = data.tripRequest.return.recurringType;
        dataToBeLogged['return-extra-seats-handicapped'] = data.tripRequest.return.extraSeats.handicapped;
        dataToBeLogged['return-extra-seats-ambulatory'] = data.tripRequest.return.extraSeats.ambulatory;
      }
      dispatch(trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Select A Payment Method',
        subJourneyName: 'Book A Ride',
        journeyName: 'Rider Experience',
        elementId: 'booking-methods-container',
        details: {
          'promo-code-used': !!promoInStore,
          ...dataToBeLogged,
        },
      }));

      if (isSelfPaid) {
        await getSelectedTripPricing(data, dispatch, state);
      }

      if (isPointsPaid) {
        await getSelectedTripPricing(data, dispatch, state);
        await getSelectedTripPoints(data, dispatch);
      }

      dispatch(setLoading(null));
    } catch (error) {
      if (promo) {
        dispatch(
          trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
            eventName: analytics.INVALID_PROMO_CODE,
            subJourneyName: 'Trip Overview',
            journeyName: 'Rider Experience',
            details: { promoCode },
          }),
        );
        dispatch(
          setPromoCode({
            code: promo,
            applied: false,
          }),
        );
      }
      // eslint-disable-next-line no-console
      console.log('GET_TRIP_PRICING_AND_COUNT_ERROR', error);
      dispatch(setLoading(null));
    }
  };
};

export const tripRequestReducer = TripRequestSlice.reducer;
