/** @format */
import { generals } from '@constants';
import moment from 'moment';
import momentTz from 'moment-timezone';

const geoTz = require('geo-tz');

const pointInPolygon = (point, polygon) => {
  const x = point[0];
  const y = point[1];

  let inside = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    // eslint-disable-next-line
    const intersect =
      (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

const createSteps = tripSteps => {
  const returnedSteps = tripSteps.map(stepName => {
    return generals.TRIP_OPTION_STEPS[stepName];
  });
  return returnedSteps;
};

const synchronizeMinsAndHours = (pickerTime, queryTime) => {
  const getMinutesHoursFromTime = time => {
    return {
      hours: moment(time).format('HH'),
      mins: moment(time).format('mm'),
    };
  };

  const today = moment().format('YYYY-MM-DD');

  if (
    getMinutesHoursFromTime(pickerTime).mins >= '55' &&
    queryTime &&
    queryTime.split(':')[1] === '00'
  ) {
    return moment(
      `${today} ${
        parseInt(getMinutesHoursFromTime(pickerTime).hours, 10) === 0
          ? 11
          : parseInt(queryTime.split(':')[0], 10) - 1
      }:${getMinutesHoursFromTime(pickerTime).mins}:00`,
    );
  }

  if (
    getMinutesHoursFromTime(pickerTime).mins === '00' &&
    queryTime &&
    queryTime.split(':')[1] >= '55'
  ) {
    return moment(
      `${today} ${
        parseInt(getMinutesHoursFromTime(pickerTime).hours, 10) === 11
          ? 0
          : parseInt(queryTime.split(':')[0], 10) + 1
      }:${getMinutesHoursFromTime(pickerTime).mins}:00`,
    );
  }

  return pickerTime;
};

const checkExistingQueryParams = (queryParams) =>  (
    Object.keys(queryParams).length !== 0 &&
    !queryParams.pid &&
    !queryParams.accessToken &&
    !queryParams.tripId &&
    !queryParams.pickup &&
    !queryParams.dropoff &&
    !queryParams.oneWayDateTime &&
    !queryParams.pickuplat &&
    !queryParams.pickuplng &&
    !queryParams.dropofflat &&
    !queryParams.dropofflng &&
    !queryParams.type
) 
const convertTimeToGivenTimeZone = (
  time,
  timezone,
  outputFormat = 'MM/DD/YYYY HH:mm:ss',
  inputFormat = 'MM/DD/YYYY HH:mm:ss',
) => {
  const newTime = momentTz.utc(time, inputFormat).tz(timezone || 'America/New_York');
  return newTime && momentTz(newTime).isValid
    ? newTime.format(outputFormat)
    : time;
};
const convertTripTimeToGivenTimeZone = (
  trip,
  time,
  outputFormat = 'MM/DD/YYYY HH:mm:ss',
  inputFormat = 'MM/DD/YYYY HH:mm:ss',
) => {
  return convertTimeToGivenTimeZone(
    time,
    trip.places[0].timezone,
    outputFormat,
    inputFormat,
  );
};

export const getAddressWithTimeZone = address => {
  if (address && !address.timezone) {
    let timezone = '';

    try {
      timezone = geoTz(address.lat, address.lng).toString();
    } catch {
      timezone = 'America/New_York';
    }

    return {
      ...address,
      timezone,
    };
  }
  return address;
};

const getShiftForArrival = (arrival, dropoff) => {
  if (dropoff.orgPlaceShifts && dropoff.orgPlaceShifts.length && dropoff.orgPlaceType === 'WORKPLACE') {
    const arriveTime = moment(arrival);
    const date = arriveTime.format('MM/DD/YYYY');
    const shifts = dropoff.orgPlaceShifts;
    let shift = null;

    for (let i = 0; i < shifts.length; i += 1) {
      if (
        arriveTime.isBetween(
          moment(`${date} ${shifts[i].startTime}`).subtract(30, 'minutes'),
          moment(`${date} ${shifts[i].endTime}`),
        )
      ) {
        shift = `${shifts[i].startTime}`;
        break;
      }
    }

    return shift;
  }

  return null;
};

const getFixedRoutePrePopulateURL = trips => {
  let pickup = '';
  let dropoff = '';
  let oneWayDateTime = '';
  let type = 'Ready By';
  if (trips.share.depart.length > 0) {
    pickup = trips.share.depart[0].places.find(p => p.type === 'Pick-up');
    dropoff = trips.share.depart[0].places.find(p => p.type === 'Drop-off');
    oneWayDateTime = moment(trips.share.depart[0].leaveTime).utc().format('MM/DD/YYYY hh:mm:ss');
  }
  if (trips.share.return.length > 0) {
    type = 'Arrive By';
  }
  return {
    pickup,
    dropoff,
    oneWayDateTime,
    type,
  };
};

export default {
  createSteps,
  synchronizeMinsAndHours,
  convertTripTimeToGivenTimeZone,
  convertTimeToGivenTimeZone,
  getAddressWithTimeZone,
  getShiftForArrival,
  getFixedRoutePrePopulateURL,
  pointInPolygon,
  checkExistingQueryParams
};
