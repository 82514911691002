/** @format */

import React, { useRef, useEffect, useState } from 'react';
import { OrganizationPlaceCardItem } from '@components';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSearchQuery, setSearchTripTypeIndex, getEstimatedShiftPickupTime } from '@slices/tripRequest';
import {
  setSelectedOrganizationPlaceMarker,
  setShowOrgPlaceMarkerSlider,
  trackAbEvent,
} from '@slices/app';
import { toast, placeHelpers, placesTimingHelpers, tripHelpers } from '@utils';
import { useHistory } from 'react-router-dom';
import { styles, analytics } from '@constants';
import { Wrapper, Backdrop, SliderComponent, ButtonWrapper } from './styled';

const OrganizationPlaceCards = props => {
  const { t } = useTranslation();
  const [organizationPlacesMapMarkers, setOrganizationPlacesMapMarkers] =
    useState([]);
  const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';
  const [polygon, setPolygon] = useState(null);
  const [shiftTime, setShiftTime] = useState();
  const [selectedWorkplace, setSelectedWorkplace] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    search,
    estShiftPickupTime,
    loading,
  } = useSelector(state => state.tripRequest);

  const {
    organizationPlaces,
    selectedOrganizationPlaceMarker,
    organizations,
    selectedOrganization,
    user,
    showOrgPlaceMarkerSlider,
  } = useSelector(state => state.app);

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0)
    ) {
      const { detail } = organizations[selectedOrganization] || {};
      const { polygonRestriction, polygon: polygonShape } = detail || {};
      if (polygonRestriction && polygonShape) {
        setPolygon(polygonShape);
      }
    }
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0) &&
      Array.isArray(organizationPlaces) &&
      organizationPlaces.length
    ) {
      const { workplaceId } = organizations[selectedOrganization];
      const workplace = organizationPlaces.find(
        place => place.orgPlaceId === workplaceId,
      );
      setSelectedWorkplace(workplace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationPlaces, organizations, selectedOrganization]);

  useEffect(() => {
    setOrganizationPlacesMapMarkers(
      placesTimingHelpers.calculateAvailableTimings(organizationPlaces),
    );
  }, [organizationPlaces]);

  const sliderRef = useRef(null);
  const [wrapperDisplay, setWrapperDisplay] = useState(false);

  useEffect(() => {
    if (selectedOrganizationPlaceMarker?.shouldScrollToCard) {
      scrollToSelectedOrganizationPlaceMarker();
    }
    // eslint-disable-next-line
  }, [selectedOrganizationPlaceMarker?.name, showOrgPlaceMarkerSlider]);

  useEffect(() => {
    // eslint-disable-next-line
    settings = {
      ...settings,
      slidesToScroll: props.isSmall ? 1 : 3,
      className: 'center',
      centerMode: true,
    };
  }, []);

  useEffect(() => {
    if (!user) {
      // Logged out
      dispatch(setShowOrgPlaceMarkerSlider(false));
    }
    // User has logged in
    if (
      user &&
      organizationPlacesMapMarkers?.length > 0 &&
      !props.isSmall &&
      !showOrgPlaceMarkerSlider
    ) {
      dispatch(setShowOrgPlaceMarkerSlider(true));
    }
    // eslint-disable-next-line
  }, [user, organizationPlacesMapMarkers.length]);

  useEffect(() => {
    if (!shouldRender()) {
      setTimeout(() => {
        // To make sure conditions are true even after time lag
        if (!shouldRender()) {
          setWrapperDisplay(false);
        }
      }, styles.ANIM_PLACE_CARD_SLIDE_UP_TIME);
    } else {
      setWrapperDisplay(true);
    }
    // eslint-disable-next-line
  }, [showOrgPlaceMarkerSlider, props.isHome]);

  const shouldRender = () => {
    return (
      (props.isSmall && showOrgPlaceMarkerSlider) ||
      (!props.isSmall && props.isHome)
    );
  };

  useEffect(() => {
    if (
      estShiftPickupTime &&
      Array.isArray(estShiftPickupTime) &&
      estShiftPickupTime.length
    ) {
      const shiftMap = new Map(
        estShiftPickupTime?.map(shift => {
          return [shift.shiftId, shift.estTime];
        }),
      );
      setShiftTime(shiftMap);
    }
  }, [estShiftPickupTime]);

  const onAddressClick = (event, key, selected) => {
    event.stopPropagation();

    const otherAddress = key === 'pickup' ? search.query.destinationAddress : search.query.pickupAddress;

    // polygon restriction check
    const address = tripHelpers.getAddressWithTimeZone(selected);
    const { lat, lng, label, orgPlaceId } = address || {};
    if (lat && lng && !!polygon && !orgPlaceId) {
      const insidePolygon = tripHelpers.pointInPolygon([parseFloat(lat), parseFloat(lng)], polygon);

      if (!insidePolygon) {
        toast.show('error', `The selected address "${label}" is outside the defined region.`);
        return;
      }
    }

    // if other address is defined, check for same addresses
    if (otherAddress?.lat) {
      const isSame = placeHelpers.areSamePlaces(selected, otherAddress);

      if (isSame) {
        toast.show('error', t('trip-request.messages.same-location-invalid'));
        return;
      }
    }

    dispatch(
      setSearchQuery({
        key: `${key}Address`,
        value: selected,
      }),
    );

    if (workforceEnv) {
      dispatch(
        setSearchQuery({
          key: 'destinationAddress',
          value: selectedWorkplace,
        }),
      );
      dispatch(setSearchTripTypeIndex(1));
      dispatch(getEstimatedShiftPickupTime(selected.orgPlaceId));
    }
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.GO_HERE_CLICKED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          selectedAddress: selected,
        },
      }),
    );
    dispatch(
      setSelectedOrganizationPlaceMarker({
        ...selected,
        shouldFlyToMarker: true,
        shouldScrollToCard: false,
      }),
    );

    if (props.isSmall) {
      dispatch(setShowOrgPlaceMarkerSlider(false));
      history.push('/trip-request');
    }
  };

  const goToNext = () => sliderRef.current.slickNext();

  const goToBack = () => sliderRef.current.slickPrev();

  let settings = {
    centerMode: props.isSmall ? true : false,
    slidesToShow: 1,
    speed: props.isSmall ? 500 : 0, // On scroll, how fast should be scroll speed, lesser the number, faster the speed
    slidesToScroll: 1, // On one scroll instance, how many cards should be swiped
    dots: false, // Pagination Dots
    infinite: false,
    cssEase: 'linear',
    variableWidth: true,
    variableHeight: true,
    arrows: false,
    afterChange: (indexOfCurrentSlide) => {
      setCurrentIndex(indexOfCurrentSlide);
    }
  };

  const scrollToSelectedOrganizationPlaceMarker = () => {
    if (sliderRef) {
      const currSlideIndex = organizationPlacesMapMarkers.findIndex(
        x => x?.name === selectedOrganizationPlaceMarker?.name,
      );

      if (currSlideIndex >= 0) {
        if (sliderRef && sliderRef.current && sliderRef.current.slickGoTo) {
          sliderRef.current.slickGoTo(currSlideIndex, false);
        }
      }
    }
  };

  const onCardSelect = place => {
    if (place?.orgPlaceId === selectedOrganizationPlaceMarker?.orgPlaceId) {
      dispatch(setSelectedOrganizationPlaceMarker(null));
    } else {
      dispatch(
        setSelectedOrganizationPlaceMarker({
          ...place,
          shouldScrollToCard: false,
          shouldFlyToMarker: false,
        }),
      );
      if (workforceEnv) {
        dispatch(getEstimatedShiftPickupTime(place.orgPlaceId));
      }
    }
  };

  const getOrganizationName = x => {
    if (x.organizationId === 1) {
      return 'Share Mobility';
    }

    return organizations?.find(o => o.organizationId === x.organizationId)
      ?.organization?.name;
  };

  const onSwipedDown = () => {
    dispatch(setShowOrgPlaceMarkerSlider(false));
  };

  const checkIfVisible = () => {
    if (!user) return false;
    if (props.isSmall && showOrgPlaceMarkerSlider) {
      return true;
    }

    if (!props.isSmall && props.isHome) {
      return true;
    }

    return false;
  };
  return (
    props.isHome ?
    <>
    {organizationPlacesMapMarkers.length > 0 &&
      <ButtonWrapper>
        <button disabled={currentIndex === 0} onClick={goToBack} className='prev-btn btn'>←</button>
        <button disabled={currentIndex === organizationPlacesMapMarkers?.length - 1} onClick={goToNext} className='next-btn btn'>→</button>
      </ButtonWrapper>
      }
      <Wrapper
        sliderVisible={checkIfVisible()}
        isSmall={props.isSmall}
        id="org-place-cards-wrapper"
        wrapperDisplay={wrapperDisplay}
        className={`${wrapperDisplay.toString()}`}
      >
      
        <SliderComponent
          {...settings}
          className="SliderComponent"
          ref={sliderRef}
          isSmall={props.isSmall}
          data-cy="location-cards-slider"
        >
          {organizationPlacesMapMarkers?.map((x, i) => (
            <OrganizationPlaceCardItem
              key={x?.orgPlaceId}
              index={i}
              selected={x?.orgPlaceId === selectedOrganizationPlaceMarker?.orgPlaceId}
              place={x}
              onAddressClick={onAddressClick}
              onCardSelect={onCardSelect}
              organizationName={getOrganizationName(x)}
              onSwipedDown={onSwipedDown}
              selectedWorkplace={selectedWorkplace}
              estPickupTime={shiftTime}
              loading={loading}
            />
          ))}
        </SliderComponent>
        <Backdrop />
      </Wrapper>
    </>
    : null
  );
};

OrganizationPlaceCards.propTypes = {
  isSmall: PropTypes.bool,
};

OrganizationPlaceCards.default = {
  isSmall: false,
};

export default OrganizationPlaceCards;
