/** @format */
import moment from 'moment';
import momentTz from 'moment-timezone';
import { toast } from '@utils';
import { getTranslatedStringFromKey } from '../../i18Loader';

const getObjectProperty = (obj, keys, defaultVal) => {
  if (!obj) return defaultVal || obj;

  const keysArray = Array.isArray(keys) ? keys : keys.split('.');

  const value = obj[keysArray[0]];

  if (value && keysArray.length > 1) {
    return getObjectProperty(value, keysArray.slice(1), defaultVal);
  }

  return value === undefined || value === null ? defaultVal : value;
};

const isObjectEmpty = obj => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const convertToUtcTime = timeString => {
  const datetime = moment(`${moment().format('YYYY-MM-DD')}T${timeString}`);
  return moment.utc(datetime).format('HH:mm:ss');
};
const convertToUtcDateTime = datetime => {
  const localDateTime = moment(datetime);
  return moment.utc(localDateTime).format('MM/DD/YYYY HH:mm:ss');
};

const convertToUtcDateTimeWTimezone = (datetime, timezone) => {
  return momentTz
    .tz(datetime, 'YYYY-MM-DDTHH:mm:ss', timezone)
    .utc()
    .format('MM/DD/YYYY HH:mm:ss');
};

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

const deepClone = o => JSON.parse(JSON.stringify(o));

const formatPhoneNumber = phone => {
  if (!phone) return null;

  return phone.split('').reduce((formatted, digit, i) => {
    if (i === 3 || i === 6) {
      return `${formatted}-${digit}`;
    }

    return formatted + digit;
  }, '');
};

const getNumberOfdaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const getMonthNameFromNumber = month => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months[month - 1];
};

const getMonthNumberFromName = month => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months.indexOf(month) + 1;
};

const getDaysInMonth = numberOfdaysInMonth => {
  const days = [];
  for (let i = 1; i <= numberOfdaysInMonth; i += 1) {
    days.push(i);
  }
  return days;
};

const getYearsDOB = value => {
  const years = [];
  for (let i = moment(value || new Date()).get('year'); i >= 1900; i -= 1) {
    years.push(i);
  }
  return years;
};

const parseQueryString = queryString => {
  if (!queryString) return {};

  const query = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

const truncateStringWithEllipses = (str, n) => {
  if (!str) {
    return str;
  }

  return str.length > n ? `${str.substr(0, n - 1)} ...` : str;
};

const getStreetAddress = address => {
  if (address) {
    let streetAddress = [];
    if (Array.isArray(address) && address[0]) {
      streetAddress = address[0].split(', ');
    } else {
      streetAddress = address.split(', ');
    }

    if (streetAddress.length === 1) {
      return streetAddress;
    }

    return `${streetAddress[0]}, ${streetAddress[1]}`;
  }
  return null;
};

const timeWithoutSeconds = time => {
  let updatedTime = time.split('min');
  updatedTime = `${updatedTime[0]}min`;
  return updatedTime;
};

const queryFilter = (array, query, keys) => {
  return array.filter(o =>
    keys.reduce((result, current) => {
      return (
        result || (o[current] && o[current].toLowerCase().indexOf(query.toLowerCase()) !== -1)
      );
    }, false),
  );
};

const SEARCHBOX_UA_REGEX =
  /alexa|bot|crawl(er|ing)|facebookexternalhit|feedburner|google web preview|nagios|postrank|pingdom|slurp|spider|yahoo!|yandex/;

/* eslint-disable no-useless-escape */
const userAgentRules = [
  ['aol', /AOLShield\/([0-9\._]+)/],
  ['edge', /Edge\/([0-9\._]+)/],
  ['edge-ios', /EdgiOS\/([0-9\._]+)/],
  ['yandexbrowser', /YaBrowser\/([0-9\._]+)/],
  ['kakaotalk', /KAKAOTALK\s([0-9\.]+)/],
  ['samsung', /SamsungBrowser\/([0-9\.]+)/],
  ['silk', /\bSilk\/([0-9._-]+)\b/],
  ['miui', /MiuiBrowser\/([0-9\.]+)$/],
  ['beaker', /BeakerBrowser\/([0-9\.]+)/],
  ['edge-chromium', /EdgA?\/([0-9\.]+)/],
  [
    'chromium-webview',
    /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/,
  ],
  ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
  ['phantomjs', /PhantomJS\/([0-9\.]+)(:?\s|$)/],
  ['crios', /CriOS\/([0-9\.]+)(:?\s|$)/],
  ['firefox', /Firefox\/([0-9\.]+)(?:\s|$)/],
  ['fxios', /FxiOS\/([0-9\.]+)/],
  ['opera-mini', /Opera Mini.*Version\/([0-9\.]+)/],
  ['opera', /Opera\/([0-9\.]+)(?:\s|$)/],
  ['opera', /OPR\/([0-9\.]+)(:?\s|$)/],
  ['ie', /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
  ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
  ['ie', /MSIE\s(7\.0)/],
  ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
  ['android', /Android\s([0-9\.]+)/],
  ['ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/],
  ['safari', /Version\/([0-9\._]+).*Safari/],
  ['facebook', /FBAV\/([0-9\.]+)/],
  ['instagram', /Instagram\s([0-9\.]+)/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Gecko\)$/],
  ['curl', /^curl\/([0-9\.]+)$/],
  ['searchbot', SEARCHBOX_UA_REGEX],
];
/* eslint-disable no-useless-escape */

function matchUserAgent(ua) {
  // opted for using reduce here rather than Array#first with a regex.test call
  // this is primarily because using the reduce we only perform the regex
  // execution once rather than once for the test and for the exec again below
  // probably something that needs to be benchmarked though
  return (
    ua !== '' &&
    userAgentRules.reduce((matched, [browser, regex]) => {
      if (matched) {
        return matched;
      }

      const uaMatch = regex.exec(ua);
      return !!uaMatch && [browser, uaMatch];
    }, false)
  );
}

function browserName() {
  const ua = navigator.userAgent;
  const data = matchUserAgent(ua);
  return data ? data[0] : null;
}

const areObjectKeysEqual = (keys, firstObject, secondObject) => {
  let isEqual = true;

  keys.forEach(key => {
    if (firstObject[key] !== secondObject[key]) {
      isEqual = false;
    }
  });

  return isEqual;
};

const camelize = str => {
  return str.trim().toLowerCase().replace(/[^a-zA-Z_ ]/g, '').replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

const camelToKebabCase = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

const convertCamelCaseToWords = str => {
  const name = str.replace(/([A-Z])/g, ' $1');
  return capitalizeFirstLetter(name);
};

const multiReplace = (string, data = {}) => {
  let resultantStr = string;
  Object.keys(data).forEach(key => {
    resultantStr = resultantStr.replace(key, data[key]);
  });
  return resultantStr;
};

const copyToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    navigator.clipboard.writeText(textToCopy);
    toast.show(
      'success',
      getTranslatedStringFromKey('common.copied-to-clipboard'),
    );
  } else {
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      toast.show(
        'success',
        getTranslatedStringFromKey('common.copied-to-clipboard'),
      );
      textArea.remove();
    });
  }
};

const QRPrint = (imgUrl) => {
  let timeoutHandler;
  const popup = window.open('', '', 'height=700, width=700');
  const styles = `<style> 
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      } 
      
      img {
        width: 470px;
        height: 470px;
      } 
    </style>`;
  popup.document.write(styles);
  popup.document.write(`<div> <img src=${imgUrl} alt="QR Code" /> </div>`);
  if (timeoutHandler) {
      clearTimeout(timeoutHandler);
  }

  timeoutHandler = setTimeout(() => {
      popup.document.close();
      popup.print();
  }, 1500);
};

const QRDownload = ({ authQRCode, id }) => {
  const img = document.createElement('a');
  img.href = authQRCode;
  img.download = `${id}.png`;
  img.target = '_blank';
  document.body.appendChild(img);
  img.click();
  document.body.removeChild(img);
};

const isExistingUser = (user) => {
  // returns true if user's createdAt date is older than two weeks
  const twoWeeksAgo = moment().subtract(2, 'weeks');
  return moment(user.createdAt).isBefore(twoWeeksAgo);
};

export default {
  getObjectProperty,
  capitalizeFirstLetter,
  deepClone,
  formatPhoneNumber,
  getDaysInMonth,
  getYearsDOB,
  parseQueryString,
  truncateStringWithEllipses,
  getStreetAddress,
  timeWithoutSeconds,
  queryFilter,
  getNumberOfdaysInMonth,
  getMonthNumberFromName,
  getMonthNameFromNumber,
  convertToUtcTime,
  convertToUtcDateTime,
  browserName,
  isObjectEmpty,
  convertToUtcDateTimeWTimezone,
  areObjectKeysEqual,
  camelize,
  camelToKebabCase,
  convertCamelCaseToWords,
  multiReplace,
  copyToClipboard,
  QRPrint,
  QRDownload,
  isExistingUser,
};
