/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Dropdown } from '@components';

import { theme } from '@constants';
import { styles } from '@utils';

export const PickerWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 4px 0 ${styles.hexToRgba(theme.BLACK, 0.16)};
  background-color: ${theme.WHITE};
  padding: 20px;
  width: 100%;
  margin: 10px 0;
`;

export const TextSmall = styled.small`
  color: ${theme.BASE_COLOR};
  font-weight: bold;
  font-size: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
`;

export const Select = styled.select`
  background: ${theme.BASE_COLOR};
  font-size: 12px;
  color: ${theme.WHITE};
`;

export const RdtDatePickerWrapper = styled.div`
  position: absolute;
  z-index: 5;
  background: ${theme.WHITE};
  box-shadow: 0 1px 3px ${styles.hexToRgba(theme.BLACK, 0.1)};
  border: 1px solid ${theme.LIGHTER_GREY};
  min-width: 250px;
  top: -150px;
`;

export const RdtTimePickerWrapper = styled(RdtDatePickerWrapper)`
  min-width: 150px;
`;

export const ValidTimesDropdownWrapper = styled.div`
  width: 100%;
`;

export const ValidTimesDropdown = styled(Dropdown)`
  .rdd-placeholder {
    font-size: 16px;
    z-index: 7;
  }
  .rdd-control {
    z-index: 7;
  }

  .rdd-menu {
    max-height: 120px;
    z-index: 6;
    position: absolute;
    bottom: 32px;
    top: auto;
  }

  .rdd-menu > div {
    font-size: 16px;
  }

  .rdd-menu > div:first-child {
    margin-top: 0px;
  }
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
`;
