/** @format */

const seenHints = {
  tripRequestLarge: false,
  tripRequestSmallStep1: false,
  tripRequestSmallStep2: false,
  tripRequestSmallStep3: false,
  tripDateTimeFormSmallStep1: false,
  tripDateTimeFormSmallStep2: false,
  tripOptions: false,
  mapWorkforce: false,
  tripOptionsWorkforce: false,
  tripOverviewWorkforce: false,
};

const tripRequestLargeHints = {
  pickupAddress: 'trip-request.large-screen.fields.pickup.hint',
  destinationAddress: 'trip-request.large-screen.fields.destination.hint',
  departDate: 'trip-request.large-screen.fields.depart-date.hint',
  departTime: 'trip-request.large-screen.fields.depart-time.hints.custom',
  departTimeDropdown: 'trip-request.large-screen.fields.depart-time.hints.list',
  returnDate: 'trip-request.large-screen.fields.return-date.hint',
  returnTime: 'trip-request.large-screen.fields.return-time.hints.custom',
  returnTimeDropdown: 'trip-request.large-screen.fields.return-time.hints.list',
  isReturnToggle: 'trip-request.large-screen.fields.switch.labels.hint',
  submitBtn: 'trip-request.large-screen.buttons.find-rides.hint',
};

const tripRequestSmallHints = {
  pickupAddress: 'trip-request.small-screen.fields.pickup.hint',
  departDateTime: 'trip-request.small-screen.fields.depart-date.hint',
  destinationAddress: 'trip-request.small-screen.fields.destination.hint',
  returnDateTime: 'trip-request.small-screen.fields.return-date.hint',
  isReturnToggle: 'trip-request.small-screen.fields.switch.hint',
  submitBtn: 'trip-request.small-screen.buttons.find-rides.hint',
};

const fixedRouteLargeHints = {
  pickupAddress: 'fixed-route.large-screen.fields.pickup.hint',
  checkbox: 'fixed-route.large-screen.fields.checkbox.hint',
  dropdown: 'fixed-route.large-screen.fields.dropdown.hint',
  destinationAddress: 'fixed-route.large-screen.fields.destination.hint',
  date: 'fixed-route.large-screen.fields.date.hint',
  time: 'fixed-route.large-screen.fields.time.hint',
  toggle: 'fixed-route.large-screen.fields.switch.labels.hint',
  submitBtn: 'fixed-route.large-screen.buttons.find-rides.hint',
};

const fixedRouteSmallHints = {
  pickupAddress: 'fixed-route.small-screen.fields.pickup.hint',
  checkbox: 'fixed-route.small-screen.fields.checkbox.hint',
  dropdown: 'fixed-route.small-screen.fields.dropdown.hint',
  destinationAddress: 'fixed-route.small-screen.fields.destination.hint',
  date: 'fixed-route.small-screen.fields.date.hint',
  time: 'fixed-route.small-screen.fields.time.hints.custom',
  toggle: 'fixed-route.small-screen.switch.hint',
  submitBtn: 'fixed-route.small-screen.buttons.find-routes.hint',
};

const tripDepartDateTimeFormSmall = {
  date: 'trip-request.small-screen.depart-date-time.fields.date.hint',
  time: 'trip-request.small-screen.depart-date-time.fields.time.hint',
};

const tripReturnDateTimeFormSmall = {
  date: 'trip-request.small-screen.return-date-time.fields.date.hint',
  time: 'trip-request.small-screen.return-date-time.fields.time.hint',
};

const tripOptions = {
  departTripTypeFilter: 'trip-options.hints.departTripTypeFilter',
  departingTrip: 'trip-options.hints.departingTrip',
  returnTripTypeFilter: 'trip-options.hints.returnTripTypeFilter',
  returnTrip: 'trip-options.hints.returnTrip',
  confirm: 'trip-options.hints.confirm',
};

const mapWorkforceHints = {
  marker: 'map.hints.marker'
};

const tripOptionsWorkforceHints = {
  card: 'trip-options.workforce.hints.card'
};

const tripOverviewWorkforceHints = {
  heading: 'trip-overview.workforce.hints.heading',
  returnDatetime: 'trip-overview.workforce.hints.return',
  departDatetime: 'trip-overview.workforce.hints.depart',
  repeat: 'trip-overview.workforce.hints.repeat',
  bookRideBtn: 'trip-overview.workforce.hints.book-ride'
};

export default {
  seenHints,
  tripRequestLargeHints,
  tripRequestSmallHints,
  fixedRouteLargeHints,
  fixedRouteSmallHints,
  tripDepartDateTimeFormSmall,
  tripReturnDateTimeFormSmall,
  tripOptions,
  mapWorkforceHints,
  tripOptionsWorkforceHints,
  tripOverviewWorkforceHints
};
