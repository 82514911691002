/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Col, MultiSelect, Switch } from '@components';
import { generals as generalConstants, theme } from '@constants';
import { LabelSmall, Wrapper, LabelRow } from './styled';

const SpecialNeedsField = ({
  onChange,
  specialNeeds,
  onChangeShowSpecialNeed,
  showSpecialNeed,
  ...restProps
}) => {
  const { t } = useTranslation();
  const specialNeedsArray = generalConstants.SPECIAL_NEEDS.map(x => {
    return { ...x, label: t(x.label) };
  });
  const needs = specialNeeds
    ? specialNeeds.map(need => ({
        label: t(`special-needs.options.${need.toLowerCase().split(' ').join('-')}`),
        value: need,
      }))
    : [];

  return (
    <Col align="flex-start" zIndex="0" justify="flex-start" spacingV={10}>
      <LabelRow spacingV={10} justify="space-between" align="center">
        <LabelSmall>{t('special-needs.labels.question')}</LabelSmall>

        <Switch
          data-cy="special-field-switch"
          id="special-field-switch"
          checked={showSpecialNeed === 'Yes' || needs.length > 0}
          onChange={onChangeShowSpecialNeed}
          unCheckedText=""
          checkedText=""
          width={60}
          height={30}
          bgColor={theme.BASE_COLOR}
          triggerColor={theme.WHITE}
          showIcon={showSpecialNeed === 'Yes' || needs.length > 0}
          opacity={showSpecialNeed === 'No' ? 0.2 : 1}
          triggerSpacing={4}
        />
      </LabelRow>
      {(showSpecialNeed === 'Yes' || needs.length > 0) && (
        <Wrapper data-cy="special-need-ms">
          <MultiSelect
            id="special-need-ms"
            onChange={onChange}
            value={needs}
            placeholder={t('special-needs.labels.needs')}
            options={specialNeedsArray}
            {...restProps}
          />
        </Wrapper>
      )}
    </Col>
  );
};

SpecialNeedsField.propTypes = {
  onChange: PropTypes.func.isRequired,
  specialNeeds: PropTypes.array.isRequired,
  onChangeShowSpecialNeed: PropTypes.func.isRequired,
  showSpecialNeed: PropTypes.string.isRequired,
};

export default SpecialNeedsField;
