/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FullScreen, LoadCredits as CLoadCredits } from '@components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { loadCreditViaPaymentMethod, setStatus } from '@slices/paymentMethods';
import { analytics, generals as generalConstants } from '@constants';
import { trackAbEvent } from '@slices/app';

const LoadCredits = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    app: { largeScreen, user, organizations, selectedOrganization },
    paymentMethods: { addedPaymentMethods: paymentMethods, addedSquareCards, loading, status },
  } = useSelector(state => state);

  const [amount, setAmount] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [maxCreditLimitForUser, setMaxCreditLimitForUser] = useState({});
  const [minCreditLimitForUser, setMinCreditLimitForUser] = useState({});
  const [formErrors, setFormErrors] = useState({
    required: null,
    maximumLimitReached: null,
    exceedsMinimumLimit: null,
  });

  useEffect(() => {
    if (organizations && organizations[selectedOrganization]) {
      setMaxCreditLimitForUser(
        parseFloat(
          organizations[selectedOrganization].organization
            .maxCreditLimitForUser,
        ),
      );
      setMinCreditLimitForUser(
        parseFloat(
          organizations[selectedOrganization].organization
            .minCreditLimitForUser,
        ),
      );
    }
  }, [organizations, selectedOrganization]);

  useEffect(() => {
    const paymentMethodOptions = (paymentMethods || []).map(paymentMethod => {
      return {
        value: paymentMethod.id,
        label: `${paymentMethod.card.brand.toUpperCase()} Ending In ${
          paymentMethod.card.last4
        }`,
      };
    });
    const squarePaymentOptions = (addedSquareCards || []).map(paymentMethod => {
      return {
        value: paymentMethod.id,
        label: `${paymentMethod.cardBrand.toUpperCase()} Ending In ${
          paymentMethod.last4
        }`,
      };
    });

    const paymentOptions = [...(paymentMethodOptions || []), ...(squarePaymentOptions || [])];

    setPaymentMethodOptions(paymentOptions);
    if (paymentOptions.length > 0) {
      handlePaymentMethodChange(paymentOptions[0], false);
    }
    if (amount <= 0 || !selectedPaymentMethod) {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [amount, paymentMethods]);

  useEffect(() => {
    if (
      status === generalConstants.LOADING_CREDITS_VIA_PAYMENT_METHOD_SUCCESS
    ) {
      setIsConfirmationModalOpen(false);
      dispatch(setStatus(null));
      history.push('/account-settings');
    }
  }, [dispatch, status, history]);

  const handleAmountChange = event => {
    const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
    const newAmount = event.target.value;
    if (!regexp.test(newAmount)) {
      return;
    }
    setAmount(newAmount);
    const newAmountF = parseFloat(newAmount);
    if (
      selectedPaymentMethod &&
      newAmountF &&
      (!maxCreditLimitForUser ||
        newAmountF <= maxCreditLimitForUser - user.balance) &&
      (!minCreditLimitForUser || newAmountF >= minCreditLimitForUser)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setAmountErrors(newAmountF);
  };

  const handlePaymentMethodChange = (selectedOption, setErrors = true) => {
    setSelectedPaymentMethod(selectedOption);
    if (
      selectedOption &&
      amount &&
      (!maxCreditLimitForUser ||
        amount <= maxCreditLimitForUser - user.balance) &&
      (!minCreditLimitForUser || amount >= minCreditLimitForUser)
    ) {
      setDisabled(false);
    }

    if (setErrors) {
      setAmountErrors(amount);
    }
  };

  const setAmountErrors = amount => {
    setFormErrors({
      required: !amount,
      maximumLimitReached: !(
        !maxCreditLimitForUser || amount <= maxCreditLimitForUser - user.balance
      ),
      exceedsMinimumLimit: !(
        !minCreditLimitForUser || amount >= minCreditLimitForUser
      ),
    });
  };

  const goBack = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.LOAD_CREDIT_CLOSED,
        subJourneyName: 'Credits',
        journeyName: 'Rider Experience',
      }),
    );

    history.push('/account-settings');
  };

  const openConfirmationModal = event => {
    event.preventDefault();
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.LOAD_CREDIT_SUBMIT_BUTTON_CLICK,
        subJourneyName: 'Credits',
        journeyName: 'Rider Experience',
      }),
    );
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationModal = isConfirmed => {
    if (loading) return;

    if (isConfirmed) {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.LOAD_CREDIT_CONFIRM_BUTTON_CLICK,
          subJourneyName: 'Credits',
          journeyName: 'Rider Experience',
        }),
      );
      dispatch(
        loadCreditViaPaymentMethod({
          paymentMethodId: selectedPaymentMethod.value,
          amount,
        }),
      );
    } else {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.LOAD_CREDIT_CANCEL_BUTTON_CLICK,
          subJourneyName: 'Credits',
          journeyName: 'Rider Experience',
        }),
      );
      setIsConfirmationModalOpen(!isConfirmationModalOpen);
    }
  };

  const getLoadCredits = () => {
    return (
      <CLoadCredits
        amount={amount}
        handleAmountChange={handleAmountChange}
        paymentMethods={paymentMethodOptions}
        selectedPaymentMethod={selectedPaymentMethod}
        handlePaymentMethodsChange={handlePaymentMethodChange}
        credits={user.balance}
        goBack={goBack}
        openConfirmationModal={openConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleConfirmationModal={handleConfirmationModal}
        isDisabled={disabled}
        handleDisabled={setDisabled}
        loading={loading}
        formErrors={formErrors}
        maxCreditLimitForUser={maxCreditLimitForUser - user.balance}
        minCreditLimitForUser={minCreditLimitForUser}
      />
    );
  };

  return largeScreen ? (
    getLoadCredits()
  ) : (
    <FullScreen id="load-credits-fullscreen">{getLoadCredits()}</FullScreen>
  );
};

export default LoadCredits;
