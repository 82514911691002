/** @format */
import CryptoJS, { AES } from 'crypto-js';
import moment from 'moment';
import { generals, localStorageKeys } from '@constants';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

const formatRidersList = riders => {
  const ridersList = [];
  if (riders && riders.length > 0) {
    for (let i = 0; i < riders.length; i += 1) {
      ridersList[i] = `${riders[i].User.name} - ${riders[i].organization.name}`;
    }
  }
  return ridersList;
};

const checkUserAgentRole = organizationUsers => {
  let userAgent = false;
  for (let i = 0; i < organizationUsers.length; i += 1) {
    if (organizationUsers[i].role === 'User Agent') {
      userAgent = true;
    }
  }
  return userAgent;
};

const getOrgDetails = orgId => {
  if (!orgId) {
    return null;
  }

  const index = generals.ORGANIZATIONS_TO_WELCOME.indexOf(orgId.toString());

  return generals.ORGANIZATION_WELCOME_DETAILS[index];
};

const getUserOrganizations = user => {
  if (!user || !user.OrganizationUsers || user.OrganizationUsers.length === 0) {
    return null;
  }

  return user.OrganizationUsers.filter(orgUser => orgUser.role === 'User');
};

const getSelectedOrgIndex = user => {
  const selectedOrg = user.detail?.selectedOrg;
  let index = 0;
  if (selectedOrg) {
    index = user.OrganizationUsers.findIndex(
      orgUser => orgUser.organizationId === selectedOrg,
    );
  }

  if (index === -1) {
    return 0;
  }

  return index;
};

const formatDriverNumber = number => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (!match) {
    return null;
  }

  return ['(', match[2], ') ', match[3], '-', match[4]].join('');
};

const getDriverContact = (user, trip) => {
  if (!trip.route || !trip.route.driverContacts?.length) {
    return null;
  }

  const { driverContact } =
    trip.route.driverContacts.find(dc => dc.riderId === user.id) || {};

  return driverContact ? formatDriverNumber(driverContact) : null;
};

const storeRefreshToken = refreshToken => {
  localStorage.setItem(
    localStorageKeys.REFRESH_TOKEN,
    AES.encrypt(JSON.stringify(refreshToken), encryptionKey).toString(),
  );
};

const getRefreshToken = () => {
  try {
    const token = localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
    if (!token) {
      return {};
    }
    const bytes = AES.decrypt(token, encryptionKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes);
  } catch (error) {
    return {};
  }
};

const isTokenExpired = expiration => {
  const timeDiff = moment(expiration, 'MM/DD/YYYY HH:mm:ss')
    .utcOffset(0, true)
    .diff(moment().utc());
  return timeDiff <= 0;
};

export default {
  formatRidersList,
  checkUserAgentRole,
  getOrgDetails,
  getUserOrganizations,
  getSelectedOrgIndex,
  getDriverContact,
  formatDriverNumber,
  storeRefreshToken,
  getRefreshToken,
  isTokenExpired,
};
