/** @format */

// eslint-disable-next-line
import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generals } from '@constants';
import { placeHelpers } from '@utils';

const useValidTimes = () => {
  const { t } = useTranslation();
  const search = useSelector(store => store.tripRequest.search);

  const getValidTimes = (type, date) => {
    const key = generals.DATE_TO_ADDRESS_MAPPING[type];
    const address = search.query[`${key}Address`];
    const day = date
      ? moment(date, 'MM/DD/YYYY').locale('en').format('ddd').toLowerCase()
      : null;

    const getValidTime = time => {
      return {
        label: time,
        value: time,
      };
    };

    const { pickupAddress, destinationAddress } = search.query;

    const getAvailableTimes = allTimes => {
      const validTimes = [];
      allTimes &&
        allTimes.forEach(time => {
          const currentUserTimeZoneUTC = moment
          .tz(moment(), pickupAddress?.timezone)
          .utc().format('YYYY-MM-DDTHH:mm:ss');

          const dateTime = `${moment(date).format('MM/DD/YYYY')} ${time}`;
          const departTime = moment
            .tz(dateTime, 'MM/DD/YYYY hh:mm A', pickupAddress?.timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss');
          moment(departTime).isAfter(currentUserTimeZoneUTC) &&
            validTimes.push(time);
        });
      return validTimes;
    };

    if (
      key === 'pickup' &&
      placeHelpers.isAirportOrTerminal(destinationAddress)
    ) {
      return {
        placeholder: t('trip-date-time-picker.arrive-at'),
        values: day
          ? getAvailableTimes(
              destinationAddress.availableTimes.arriveBy[day],
            ).map(x => getValidTime(x))
          : [],
      };
    }

    if (
      key === 'destination' &&
      placeHelpers.isAirportOrTerminal(pickupAddress)
    ) {
      return {
        placeholder: t('trip-date-time-picker.arrive-at'),
        values: day
          ? getAvailableTimes(pickupAddress.availableTimes.arriveBy[day]).map(
              x => getValidTime(x),
            )
          : [],
      };
    }

    if (address && address.organizationId) {
      return {
        placeholder: t('trip-date-time-picker.pickup-at'),
        values: day
          ? getAvailableTimes(address.availableTimes.readyBy[day]).map(x =>
              getValidTime(x),
            )
          : [],
      };
    }

    return null;
  };

  return [getValidTimes];
};

export default useValidTimes;
