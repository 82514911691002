/** @format */

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import {
  Row,
  Col,
  Input,
  SuggestionInput,
  Button,
  Dropdown,
} from '@components';

export const FormRow = styled(Row)`
  height: 90px;
`;

export const FormCol = styled(Col)`
  height: 100%;
`;

export const AddressInput = styled(SuggestionInput)`
  min-width: 250px;
  width: 100%;
`;

export const AddressInputRow = styled(Row)`
  padding-right: 15px;
`;

export const DateInput = styled(Input)`
  padding-right: 15px;
  min-width: 140px;
`;

export const TimeInput = styled(Input)`
  padding-right: 15px;
  min-width: 125px;
`;

export const FindRidesBtn = styled(Button)`
  width: 180px;
`;

export const ValidTimesDropdownWrapper = styled.div`
  margin-right: 15px;
  width: 100%;

  .rdd-placeholder {
    font-size: 16px;
    font-family: Poppins;
  }

  .rdd-menu {
    top: 40px;
    font-family: Poppins;

    > div {
      font-size: 16px;
      font-family: Poppins;
    }
  }
`;

export const ValidTimesDropdown = styled(Dropdown)`
  height: 38px;
  min-width: 150px;
`;

export const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
  }
  top: 78px !important;
`;
