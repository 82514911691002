/** @format */

import React, { useCallback, useState, useEffect } from 'react';
import { OrganizationPlaceCardsModal as COrganizationPlaceCardsModal } from '@components';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, trackAbEvent } from '@slices/app';
import { setSearchQuery, setSearchTripTypeIndex } from '@slices/tripRequest';
import { analytics } from '@constants';
import { useHistory } from 'react-router';
import { useSessionStorage } from '@hooks';

const useSelectedOrgPlaceModal = () => {
  const dispatch = useDispatch();
  const {
    selectedOrganizationPlaceMarker,
    organizationPlaces,
    organizations,
    selectedOrganization,
    largeScreen,
  } = useSelector(state => state.app);
  const { estShiftPickupTime, loading } = useSelector(state => state.tripRequest);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [selectedWorkplace, setSelectedWorkplace] = useState(null);
  const [shiftTime, setShiftTime] = useState();
  const [,, removeSearchData] = useSessionStorage('workforceTripRequestPayload');

  const openSelectedOrgPlaceModal = value => {
    setIsOpen(value);
  };

  useEffect(() => {
    if (
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0)
    ) {
      const { workplaceId } = organizations[selectedOrganization];
      const workplace = organizationPlaces.find(place => place.orgPlaceId === workplaceId);
      setSelectedWorkplace(workplace);
    }
  }, [organizations, selectedOrganization, organizationPlaces]);

    useEffect(() => {
      if (
        estShiftPickupTime &&
        Array.isArray(estShiftPickupTime) &&
        estShiftPickupTime.length
      ) {
        const shiftMap = new Map(
          estShiftPickupTime?.map(shift => {
            return [shift.shiftId, shift.estTime];
          }),
        );
        setShiftTime(shiftMap);
      }
    }, [estShiftPickupTime]);

  const close = () => {
    const OrgPlaceModalClosed =
      selectedOrganizationPlaceMarker?.type === 'WORKPLACE'
        ? analytics.WORKPLACE_TILE_CLOSED
        : analytics.PICKUP_LOCATION_TILE_CLOSED;

    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: OrgPlaceModalClosed,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          selectedAddress: selectedOrganizationPlaceMarker,
        },
      }),
    );
    setIsOpen(false);
    dispatch(closeModal({ modal: 'selectedOrgPlaceMarker', value: false }));
  };

  const handlePickup = () => {
    dispatch(
      setSearchQuery({
        key: 'pickupAddress',
        value: { ...selectedOrganizationPlaceMarker, pickupHereClicked: true },
      }),
    );
    dispatch(
      setSearchQuery({
        key: 'destinationAddress',
        value: selectedWorkplace,
      }),
    );
    dispatch(setSearchTripTypeIndex(1));
    setIsOpen(false);
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.WORKFORCE_FLOW, {
        eventName: analytics.PICKUP_HERE_SELECTED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          selectedAddress: selectedOrganizationPlaceMarker,
        },
      }),
    );
    dispatch(closeModal({ modal: 'selectedOrgPlaceMarker', value: false }));
    if (!largeScreen) {
      removeSearchData();
      history.push('/trip-request');
    }
  };

  const SelectedOrgPlaceModal = useCallback(
    () =>
      isOpen ? (
        <Portal id="selected-org-place-portal-instance">
          <COrganizationPlaceCardsModal
            selectedPlace={selectedOrganizationPlaceMarker}
            onClose={close}
            onPickupHereClick={handlePickup}
            selectedWorkPlace={selectedWorkplace}
            estPickupTime={shiftTime}
            loading={loading}
          />
        </Portal>
      ) : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, estShiftPickupTime, loading],
  );

  return {
    SelectedOrgPlaceModal,
    openSelectedOrgPlaceModal,
  };
};
export default useSelectedOrgPlaceModal;
