/** @format */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { localStorageKeys } from '@constants';
import en from './translations/en/common.json';
import es from './translations/es/common.json';
import bs from './translations/bs/common.json';
import rw from './translations/rw/common.json';
import sw from './translations/sw/common.json';
import fr from './translations/fr/common.json';
import ne from './translations/ne/common.json';
import so from './translations/so/common.json';
import sun from './translations/sun/common.json';
import pl from './translations/pl/common.json';
import vi from './translations/vi/common.json';
import zh from './translations/zh/common.json';
import ru from './translations/ru/common.json';
import 'moment/locale/es';

const resources = {
  en,
  es,
  bs,
  rw,
  sw,
  fr,
  ne,
  so,
  sun,
  pl,
  vi,
  zh,
  ru,
};

export const getUserDefaultLanguage = () => {
  const user = JSON.parse(localStorage.getItem(localStorageKeys.USER));
  if (user && user.language) {
    return user.language;
  }

  const authLessUserLanguage = localStorage.getItem(localStorageKeys.USER_LANGUAGE);
  if (authLessUserLanguage) {
    return authLessUserLanguage;
  }

  return process.env.REACT_APP_LANGUAGE;
};

const userDefaultLanguage = getUserDefaultLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: userDefaultLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

moment.locale(userDefaultLanguage); // can pass in 'en', 'fr', or 'es'

export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
  moment.locale(language);
};

export const getCurrentLanguage = () => {
  return i18n.language;
};

export const getTranslatedStringFromKey = (key, value = null) => {
  if (value) {
    return i18n.t(key, value);
  }
  return i18n.t(key);
};

export const getApiErrorTranslation = (response) => {
  if (response?.code === 'UNKNOWN_ERROR') {
    return response?.message;
  }
  const translatedString = i18n.t(`api-errors.${response?.code}`);
  if (translatedString.includes('api-errors')) {
    return response?.message;
  }
  return translatedString;
};

export default i18n;
