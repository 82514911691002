/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { theme } from '@constants';
import { helpers } from '@utils';
import { Button, Col, Row } from '@components';
import { TripDateTimeColumn, Title } from './styled';

import { TripDateTimePicker } from './tripDateTimePicker';

const TripDateTimeForm = ({
  type,
  tripDate,
  tripTime,
  isValidDate,
  getValidTimes,
  onSet,
  onBack,
  getAddressFromType,
  tripRequestAvailableDays,
  checkArriveByPlace,
}) => {
  const { t } = useTranslation();
  const [tripDateTimeData, setTripDateTimeData] = useState({
    tripDate,
    tripTime,
  });
  const [isCustomTime, setIsCustomTime] = useState(false);
  const disabled = !tripDateTimeData.tripDate || !tripDateTimeData.tripTime;

  const isValidDateWithType = current => isValidDate(current, type);
  const validTimes = getValidTimes(type, tripDateTimeData.tripDate);

  const onChange = (key, value) => {
    if (key === 'tripTime') {
      const format = 'HH:mm:ss';
      let updatedTime = moment(value, format);
      const existingTime = moment(tripDateTimeData.tripTime, format);
      const address = getAddressFromType(type);
      const date = tripDateTimeData.tripDate;

      if (tripRequestAvailableDays && !address.organizationId && date) {
        const day = moment(date).locale('en').format('dddd').toLowerCase();
        const availableTime = tripRequestAvailableDays[day];
        const availableFromTime = moment(availableTime.from, format);
        const availableToTime = moment(availableTime.to, format);

        if (
          updatedTime.isBefore(availableFromTime) ||
          updatedTime.isAfter(availableToTime)
        ) {
          updatedTime = existingTime;
        }
      }

      const formmatedTime = updatedTime.format(format);

      setTripDateTimeData({
        ...tripDateTimeData,
        [key]: validTimes ? value : formmatedTime,
      });

      return;
    }

    setTripDateTimeData({
      ...tripDateTimeData,
      [key]: value,
    });
  };

  const onOpenTimePicker = () => {
    const format = 'HH:mm:ss';
    const time = moment(`${moment().format('YYYY-MM-DD HH')}:00:00`);
    let updatedTime = moment(time, format);
    const address = getAddressFromType(type);
    const date = tripDateTimeData.tripDate;

    if (tripRequestAvailableDays && !address.organizationId && date) {
      const day = moment(date).locale('en').format('dddd').toLowerCase();
      const availableTime = tripRequestAvailableDays[day];
      const availableFromTime = moment(availableTime.from, format);
      const availableToTime = moment(availableTime.to, format);

      if (
        updatedTime.isBefore(availableFromTime) ||
        updatedTime.isAfter(availableToTime)
      ) {
        updatedTime = availableFromTime;
      }
    }

    const formmatedTime = updatedTime.format(format);

    setTripDateTimeData({
      ...tripDateTimeData,
      tripTime: formmatedTime,
    });
  };

  const onClick = () => {
    onSet({ ...tripDateTimeData, isCustomTime });
  };

  return (
    <TripDateTimeColumn id="trip-dt" justify="flex-start" align="flex-start">
      <Title>{`${helpers.capitalizeFirstLetter(type)} Trip`}</Title>
      <TripDateTimePicker
        type={type}
        tripDate={tripDateTimeData.tripDate}
        tripTime={tripDateTimeData.tripTime}
        isValidDate={isValidDateWithType}
        validTimes={validTimes}
        onChange={onChange}
        setIsCustomTime={setIsCustomTime}
        onOpenTimePicker={onOpenTimePicker}
        checkArriveByPlace={() => checkArriveByPlace(type)}
      />
      <Row justify="space-between" align="flex-end">
        <Col justify="flex-start" align="flex-start" spacingH={5}>
          <Button
            data-cy="back-btn"
            id="back-btn"
            onClick={onBack}
            color={theme.BASE_COLOR}
            inverted
            bordered
            label={t('trip-request.small-screen.buttons.back')}
          />
        </Col>
        <Col justify="flex-start" align="flex-start" spacingH={5}>
          <Button
            data-cy="set-btn"
            id="set-btn"
            onClick={onClick}
            color={theme.BASE_COLOR}
            label={t('trip-request.small-screen.buttons.set')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </TripDateTimeColumn>
  );
};

TripDateTimeForm.propTypes = {
  type: PropTypes.string.isRequired,
  tripDate: PropTypes.string.isRequired,
  tripTime: PropTypes.string.isRequired,
  isValidDate: PropTypes.func.isRequired,
  getValidTimes: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSet: PropTypes.func.isRequired,
  getAddressFromType: PropTypes.func.isRequired,
  checkArriveByPlace: PropTypes.func.isRequired,
  tripRequestAvailableDays: PropTypes.object,
};

export default TripDateTimeForm;
