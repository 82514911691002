/** @format */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  faSpinner,
  faEye,
  faEyeSlash,
  faBirthdayCake,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFocusNextInput, useMobileDatePickerDialog } from '@hooks';
import { theme } from '@constants';
import { helpers } from '@utils';
import FlagIcon from '@assets/images/usa-flag.png';
import { Button, Row, RadioGroup, RiderTypeDropdown } from '..';
import {
  Wrapper,
  FormWrapper,
  Form,
  TitleWrapper,
  Title,
  SubTitle,
  BottomWrapper,
  LoginText,
  LoginLink,
  CloseIconStyled,
  TermsAndConditionsText,
  TermsAndConditionsLink,
  DOBPicker,
  BoldText,
  CloseIconRow,
  SInput,
  SLanguageSelectorDropdown,
  CareGiverText,
} from './styled';
import config from './config';

const termsUrl = process.env.REACT_APP_TERMS_URL;
const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL;
const additionalTermsUrl = process.env.REACT_APP_ADDITIONAL_TERMS_URL;
const additionalTerms = process.env.REACT_APP_ADDITIONAL_TERMS;
const careGiverUrl = process.env.REACT_APP_CARE_GIVER_SIGNUP;
const careGiverId = process.env.REACT_APP_CAREGIVER_ORG_SIGNUP_ID;
const isAAARoundTown = process.env.REACT_APP_ENV_IDENTIFIER === 'aaa_roundtown';
const signupIdsLabel = process.env.REACT_APP_SIGNUP_IDS_LABEL;

const SignupForm = ({
  largeScreen,
  state,
  loading,
  validate,
  errors,
  signupOrganizations,
  onChange,
  onSubmit,
  onCloseModal,
  onCloseFullScreen,
  onChangeDay,
  onChangeMonth,
  onChangeYear,
  goToLoginPage,
  onChangeShowPassword,
  onChangeUserOrganization,
  onChangeLanguage,
  onChangeRiderType,
  passwordRestricted,
  language
}) => {
  const { t } = useTranslation();
  const isDobHidden = process.env.REACT_APP_DISABLE_DOB_FIELD === 'true';
  useEffect(() => {}, [passwordRestricted]);

  if (
    state.byReferralCode &&
    state.byReferralCode.length &&
    !config.formFields.find(o => o.name === 'byReferralCode')
  ) {
    config.formFields.push({
      name: 'byReferralCode',
      label: 'signup.fields.byReferralCode.label',
      type: 'text',
      readOnly: true,
      focusNext: false,
      validator: {
        required: false,
      },
    });
  }

  const formFieldsForFocusNext = config.formFields.filter(o => o.focusNext);
  const { UseMobileDatePicker, openMobileDatePickerDialog } =
    useMobileDatePickerDialog();
  const [inputRefs, focusOnNextInput] = useFocusNextInput(
    formFieldsForFocusNext,
  );
  const organization = process.env.REACT_APP_ORGANIZATION_NAME;
  const togglePassword = () => {
    onChangeShowPassword(!state.showPassword);
  };

  const openMobileDatePicker = () => {
    const { dob } = state;
    const max = isAAARoundTown
      ? new Date(`${new Date().getFullYear() - 64}-01-01`)
      : new Date(`${new Date().getFullYear() - 12}-01-01`);
    const min = new Date('1900-01-01');
    const value = new Date(`${dob.month} ${dob.date} ${dob.year}`);
    openMobileDatePickerDialog(true, {
      onSave: onChangeDate,
      max,
      min,
      value,
    });
  };

  const transformSignupOrganizations = () => {
    return signupOrganizations.map(i => {
      return {
        ...i,
        value: i.id,
      };
    });
  };

  const getMinimumDOb = () => {
    const max = isAAARoundTown
      ? new Date(`${new Date().getFullYear() - 64}-01-01`)
      : new Date(`${new Date().getFullYear() - 12}-01-01`);
    return max;
  };

  const combineDateMonthYear = value => {
    if (isDobHidden ) return false;
    if (!value.year.length) return '';
    const formattedDate = new Date(
      `${value.month} ${value.date} ${value.year}`,
    );

    return moment(formattedDate).format('DD MMMM YYYY');
  };

  const getError = formField => {
    if (formField.name === 'password') {
      return t(
        helpers.getObjectProperty(errors, [formField.name, 'message']),
       { length: formField.validator.minLength.value },
      );
    }
    return t(helpers.getObjectProperty(errors, [formField.name, 'message']));
  };

  const onChangeDate = value => {
    onChangeDay({ name: 'day', value: value.getDate().toString() });
    onChangeMonth({
      name: 'month',
      value: value.toLocaleString('default', { month: 'long' }),
    });
    onChangeYear({ name: 'year', value: value.getFullYear().toString() });
  };

  const getInputIcon = formField => {
    return formField.name === 'password'
      ? { icon: state.showPassword ? faEyeSlash : faEye }
      : { icon: formField.name === 'phone' ? FlagIcon : null };
  };

  const getInputIconProps = formField => {
    return formField.name === 'password'
      ? {
          iconProps: {
            onClick: togglePassword,
          },
        }
      : {};
  };

  const getInputRef = (ref, formField) => {
    if (!ref) {
      return;
    }

    const index = formFieldsForFocusNext.findIndex(o => o.name === ref.name);

    if (index < 0) {
      return;
    }
    inputRefs.current[index].current = ref;
    validate(ref, formField.validator);
  };
  const getFormField = () => {
    return config.formFields.map((formField, i) => {
      if (formField.name === 'dob' && isDobHidden) {
        config.formFields.splice(i, 1)
      }
      
      if (formField.name === 'password' && passwordRestricted) {
        // eslint-disable-next-line no-param-reassign
        formField = { ...formField, validator: config.passwordValidator };
      }

      if (formField.name === 'dob') {
        return largeScreen ? (
          <DOBPicker
            key={i}
            error={t(helpers.getObjectProperty(errors, [formField.name]))}
            label={t(formField.label)}
            dob={state.dob}
            min={getMinimumDOb()}
            onChangeDay={onChangeDay}
            onChangeMonth={onChangeMonth}
            onChangeYear={onChangeYear}
            fontSize={18}
          />
        ) : (
          <SInput
            data-cy="dob-input"
            floating
            key={i}
            readOnly
            type="text"
            label={t(formField.label)}
            color={theme.COD_GRAY_LIGHT}
            onClick={openMobileDatePicker}
            icon={faBirthdayCake}
            value={combineDateMonthYear(state.dob)}
          />
        );
      }

      if (formField.name === 'language') {
        return (
          <SLanguageSelectorDropdown
            key={i}
            data-cy={helpers.camelToKebabCase(formField.name)}
            onChangeLanguage={onChangeLanguage}
            userLanguage={
              language || (state[formField.name] === undefined
                ? state.detail[formField.name]
                : state[formField.name])
            }
          />
        );
      }
      if (formField.name === 'riderType') {
        return (
          <RiderTypeDropdown
            key={i}
            id="signup-form-age-group"
            data-cy={helpers.camelToKebabCase(formField.name)}
            onChangeRiderType={onChangeRiderType}
            userRiderType={state[formField.name]}
          />
        );
      }
      return (
        <SInput
          key={i}
          data-cy={helpers.camelToKebabCase(formField.name)}
          floating
          type={
            formField.name === 'password' && state.showPassword
              ? 'text'
              : formField.type
          }
          name={formField.name}
          onChange={onChange}
          onKeyPress={focusOnNextInput}
          value={
            state[formField.name] === undefined
              ? state.detail[formField.name]
              : state[formField.name]
          }
          readOnly={!!formField.readOnly}
          label={t(formField.label)}
          color={theme.COD_GRAY_LIGHT}
          inputRef={ref => getInputRef(ref, formField)}
          {...getInputIcon(formField)}
          {...getInputIconProps(formField)}
          error={getError(formField)}
        />
      );
    });
  };

  return (
    <Wrapper data-cy="signup-form">
      <FormWrapper>
        <TitleWrapper>
          <CloseIconRow align="flex-end" justify="flex-end">
            <CloseIconStyled
              data-cy="close-btn"
              onClick={largeScreen ? onCloseModal : onCloseFullScreen}
              width={20}
            />
          </CloseIconRow>
          <LoginText largeScreen={largeScreen}>
            {t('signup.labels.have-account')}{' '}
            <LoginLink
              data-cy="login-link"
              color={theme.SECONDARY_BASE_COLOR}
              onClick={goToLoginPage}
            >
              {t('login.buttons.login')}
            </LoginLink>
          </LoginText>

          <Title>{t('signup.labels.ready-to-ride')}</Title>

          {organization ? (
            <SubTitle>
              <BoldText>
                {' '}
                {t('signup.labels.create-org-account', organization)}
                {careGiverId && (
                  <CareGiverText>
                    {' '}
                    Caregivers&apos;{' '}
                    <LoginLink
                      color={theme.SECONDARY_BASE_COLOR}
                      href={careGiverUrl}
                      target="_blank"
                    >
                      {t('signup.labels.signup-here')}
                    </LoginLink>{' '}
                  </CareGiverText>
                )}
              </BoldText>
            </SubTitle>
          ) : (
            <SubTitle>{t('signup.labels.create-profile-benefits')}</SubTitle>
          )}
        </TitleWrapper>
        {signupOrganizations && signupOrganizations.length > 1 && (
          <RadioGroup
            data-cy="membership-div"
            label={signupIdsLabel ? signupIdsLabel : t('signup.labels.select-membership')}
            onChange={onChangeUserOrganization}
            name="organizationId"
            options={transformSignupOrganizations()}
            checked={state.organizationId}
          />
        )}
        <Form largeScreen={largeScreen}>{getFormField()}</Form>
      </FormWrapper>

      <Row data-cy="terms-and-conditions" justify="flex-start" align="center">
        <TermsAndConditionsText>
          {t('signup.labels.pledge')}&nbsp;
          <TermsAndConditionsLink href={termsUrl} target="_blank">
            {t('signup.labels.terms-conditions')}
          </TermsAndConditionsLink>
          {additionalTerms &&
            additionalTerms.length &&
            additionalTermsUrl &&
            additionalTermsUrl.length && (
              <>
                <TermsAndConditionsText>
                  ,&nbsp;
                </TermsAndConditionsText>
                <TermsAndConditionsLink href={additionalTermsUrl} target="_blank">
                  {additionalTerms || t('signup.labels.additional-terms-conditions')}
                </TermsAndConditionsLink>
              </>
          )}
          &nbsp;{t('signup.labels.and')}&nbsp;
          <TermsAndConditionsLink href={privacyPolicyUrl} target="_blank">
            {t('signup.labels.privacy-policy')}
          </TermsAndConditionsLink>
          .
        </TermsAndConditionsText>
      </Row>

      <BottomWrapper>
        <Button
          data-cy="register-submit-btn"
          id="submit-btn"
          onClick={onSubmit}
          label={t('signup.buttons.create-account')}
          color={theme.BASE_COLOR}
          icon={loading ? faSpinner : null}
          iconProps={{ spin: true }}
          disabled={loading}
        />
      </BottomWrapper>
      <UseMobileDatePicker />
    </Wrapper>
  );
};

SignupForm.propTypes = {
  state: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    phone: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    savedAddresses: PropTypes.array,
    specialNeeds: PropTypes.array,
    showPassword: PropTypes.bool,
    showSpecialNeed: PropTypes.string,
    detail: PropTypes.object,
    organizationId: PropTypes.number,
    byReferralCode: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCloseFullScreen: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  onChangeDay: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  goToLoginPage: PropTypes.func.isRequired,
  onChangeShowPassword: PropTypes.func.isRequired,
  onChangeUserOrganization: PropTypes.func.isRequired,
  signupOrganizations: PropTypes.array,
  onChangeLanguage: PropTypes.func.isRequired,
  onChangeRiderType: PropTypes.func.isRequired,
  passwordRestricted: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired
};

SignupForm.defaultProps = {
  signupOrganizations: null,
  isValid: null,
};

export default SignupForm;
