/** @format */

import React from 'react';
import { faSpinner, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import PropTypes from 'prop-types';
import { theme, generals as generalConstants } from '@constants';
import { Row } from '..';
import {
  AddCardButton,
  PaymentMethodsCol,
  LoaderWrapperRow,
  LoaderIcon,
  PaymentMethodsTop,
  PaymentMethodsBottomRow,
  Title,
  AddedPaymentMethodsCol,
  AddedPaymentMethod,
  AddedPaymentMethodLabel,
  AddedPaymentMethodText,
  NoPaymentMethodsText,
  AddCardCol,
  CardName,
  CardNameInput,
  CardNumber,
  CardInputLabel,
  CardDetailsRow,
  CardDetailItem,
  ZipCodeInput,
  SaveButton,
  DeleteIcon,
  CloseButton,
} from './styled';
import { cardStyles } from './config';

const StripePaymentMethods = ({
  addedPaymentMethods,
  addPaymentMethod,
  loading,
  billingDetails,
  onBillingDetailsChange,
  openConfirmDeleteModal,
  deletePaymentMethodId,
  onChangeDeletePaymentMethodId,
  setSelected,
  goBack,
}) => {
  const { t } = useTranslation();
  const { name, zipCode } = billingDetails;
  const paymentMethodsExist = addedPaymentMethods.length > 0;
  const canAddPaymentMethods = addedPaymentMethods.length < 10;

  if (loading === generalConstants.GET_PAYMENT_METHODS_LOADING || loading === generalConstants.PAYMENT_OPTIONS_LOADING) {
    return (
      <PaymentMethodsCol justify="space-between" align="center">
        <LoaderWrapperRow
          id="get-payment-methods-loader"
          justify="center"
          align="center"
        >
          <LoaderIcon icon={faSpinner} spin />
        </LoaderWrapperRow>
      </PaymentMethodsCol>
    );
  }

  return (
    <PaymentMethodsCol justify="space-between" align="center">
      <PaymentMethodsTop>
        <Title>{t('trip-overview.payment-methods.title')}</Title>

        <AddedPaymentMethodsCol
          id="added-payment-methods"
          justify="flex-start"
          align="flex-start"
          canAddPaymentMethods={canAddPaymentMethods ? 1 : 0}
        >
          {addedPaymentMethods.map((addedPaymentMethod, i) => (
            <Row
              align="center"
              justify="space-between"
              key={addedPaymentMethod.id}
            >
              <AddedPaymentMethod
                className="added-payment-method"
                key={addedPaymentMethod.id}
                onClick={() => setSelected(i)}
              >
                <AddedPaymentMethodLabel>
                  {addedPaymentMethod.card.brand.toUpperCase()}
                </AddedPaymentMethodLabel>
                <AddedPaymentMethodText>
                  {t('payments.labels.ending-in')} {addedPaymentMethod.card.last4}
                </AddedPaymentMethodText>
              </AddedPaymentMethod>

              {loading === generalConstants.DETACH_PAYMENT_METHODS_LOADING &&
              deletePaymentMethodId === addedPaymentMethod.id ? (
                <LoaderIcon icon={faSpinner} spin />
              ) : (
                <DeleteIcon
                  id="delete-payment"
                  onClick={() => {
                    onChangeDeletePaymentMethodId(addedPaymentMethod.id);
                    openConfirmDeleteModal();
                  }}
                  icon={faMinusCircle}
                />
              )}
            </Row>
          ))}

          {!paymentMethodsExist && (
            <NoPaymentMethodsText>
              {t('payments.messages.not-found')}
            </NoPaymentMethodsText>
          )}
        </AddedPaymentMethodsCol>

        {canAddPaymentMethods && (
          <AddCardCol
            justify="flex-start"
            align="flex-start"
            key={addedPaymentMethods.length}
          >
            <AddCardButton>{t('payments.buttons.add-new-card')}</AddCardButton>
            <CardName>
              <CardInputLabel>{t('payments.labels.name-on-card')}</CardInputLabel>
              <CardNameInput
                id="card-name"
                label="John Doe"
                data-cy="card-holder"
                name="name"
                value={name}
                onChange={onBillingDetailsChange}
                color={theme.BASE_COLOR}
                floating={false}
                inverted
              />
            </CardName>
            <CardNumber>
              <CardInputLabel>{t('payments.labels.card-number')}</CardInputLabel>
              <CardNumberElement data-cy="card-number" className="card-number" options={cardStyles} />
            </CardNumber>
            <CardDetailsRow justify="space-between" align="flex-start">
              <CardDetailItem>
                <CardInputLabel>{t('payments.labels.expiration')}</CardInputLabel>
                <CardExpiryElement
                  className="card-expiry"
                  data-cy="expiry-date"
                  options={cardStyles}
                />
              </CardDetailItem>

              <CardDetailItem>
                <CardInputLabel>{t('payments.labels.cvc-code')}</CardInputLabel>
                <CardCvcElement data-cy="cvc-code" className="card-cvc" options={cardStyles} />
              </CardDetailItem>

              <CardDetailItem>
                <CardInputLabel>{t('payments.labels.zip-code')}</CardInputLabel>
                <ZipCodeInput
                  className="card-zip-code"
                  data-cy="zip-code"
                  label="12345"
                  type="number"
                  name="zipCode"
                  value={zipCode}
                  onChange={onBillingDetailsChange}
                  color={theme.BASE_COLOR}
                  floating={false}
                  inverted
                />
              </CardDetailItem>
            </CardDetailsRow>
          </AddCardCol>
        )}
      </PaymentMethodsTop>

      <PaymentMethodsBottomRow justify="space-between" align="flex-end">
        <CloseButton
          id="back-btn"
          data-cy="back-btn"
          onClick={goBack}
          color={theme.BASE_COLOR}
          inverted
          bordered
          label={t('payments.buttons.back')}
        />
        <SaveButton
          id="save-btn"
          data-cy="save-btn"
          label={t('payments.buttons.save')}
          color={theme.BASE_COLOR}
          onClick={addPaymentMethod}
          bordered
          size="md"
          icon={loading ? faSpinner : null}
          iconProps={{ spin: true }}
          disabled={
            !canAddPaymentMethods ||
            !name ||
            !zipCode ||
            loading === generalConstants.ATTACH_PAYMENT_METHOD_LOADING
          }
        />
      </PaymentMethodsBottomRow>
    </PaymentMethodsCol>
  );
};

StripePaymentMethods.propTypes = {
  addedPaymentMethods: PropTypes.arrayOf(PropTypes.object),
  addPaymentMethod: PropTypes.func.isRequired,
  loading: PropTypes.oneOf([
    generalConstants.ATTACH_PAYMENT_METHOD_LOADING,
    generalConstants.GET_PAYMENT_METHODS_LOADING,
    generalConstants.DETACH_PAYMENT_METHODS_LOADING,
  ]),
  billingDetails: PropTypes.shape({
    name: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  onBillingDetailsChange: PropTypes.func.isRequired,
  openConfirmDeleteModal: PropTypes.func.isRequired,
  onChangeDeletePaymentMethodId: PropTypes.func.isRequired,
  deletePaymentMethodId: PropTypes.string,
  setSelected: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

StripePaymentMethods.defaultProps = {
  addedPaymentMethods: [],
  loading: null,
  deletePaymentMethodId: null,
};

export default StripePaymentMethods;
