/** @format */

import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { ToastContainer } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import 'react-toastify/dist/ReactToastify.min.css';
import 'intro.js/introjs.css';
import './assets/styles/introjs.css';
import reducer from '@slices';
import { Shell } from '@containers';
import { hints, generals as generalConstants, theme } from '@constants';
import { BugsnagProvider } from '@services';
import {
  googleTag,
  facebookPixel,
  googleAdRoll,
  googleTagManager,
} from '@scripts';
import './i18Loader';

// env variables
const zendeskApiKey = process.env.REACT_APP_ZENDESK_API_KEY;
const windowTitle =
  process.env.REACT_APP_WINDOW_TITLE || generalConstants.SHARE_WINDOW_TITLE;
const favIcon =
  process.env.REACT_APP_FAV_ICON || generalConstants.SHARE_FAV_ICON;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const envIdentifier = process.env.REACT_APP_ENV_IDENTIFIER;
const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
const googleTagId = process.env.REACT_APP_GOOGLE_TAG_ID;

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const stripePromise = loadStripe(stripePublicKey);

const seenHints = JSON.parse(localStorage.getItem('seenHints'));
if (!seenHints) {
  localStorage.setItem('seenHints', JSON.stringify(hints.seenHints));
}
const ErrorBoundary = BugsnagProvider(store);

const setting = {
  color: {
    theme: theme.BASE_COLOR,
  },
};

function App() {
  return (
    <ErrorBoundary>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <Shell />
          <ToastContainer />
          {zendeskApiKey && <Zendesk zendeskKey={zendeskApiKey}  {...setting} onLoaded={()=> ZendeskAPI('webWidget', 'hide')} />}
          <Helmet>
            <title>{windowTitle}</title>
            <link rel="icon" href={favIcon} />
            {['share_mobility', 'aaa_roundtown'].includes(envIdentifier) &&
              facebookPixelId && (
                <script nonce="fb-pixel">{facebookPixel(facebookPixelId)}</script>
              )}
            {['share_mobility'].includes(envIdentifier) && (
              <script
                nonce="google-tag-manager"
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}
              />
            )}
            {['aaa_roundtown'].includes(envIdentifier) && (
              <script nonce="google-ad-roll">{googleAdRoll()}</script>
            )}
            {['share_mobility'].includes(envIdentifier) && googleTagId && (
              <script nonce="google-tag">{googleTag(googleTagId)}</script>
            )}

            {['aaa_roundtown'].includes(envIdentifier) && (
              <script nonce="google-tag">{googleTagManager()}</script>
            )}
          </Helmet>
          {envIdentifier === 'share_mobility' && (
            <noscript>
              <img
                height="1"
                width="1"
                alt="facebook"
                src="https://www.facebook.com/tr?id=887765165127237&ev=PageView&noscript=1"
              />
            </noscript>
          )}

          {envIdentifier === 'aaa_roundtown' && (
            <noscript>
              <iframe
                title="Google Tag Manager"
                src="https://www.googletagmanager.com/ns.html?id=GTM-MR59WJ"
                height="0"
                width="0"
                style={{
                  display: 'none',
                  visibility: 'hidden',
                }}
              />
            </noscript>
          )}
        </Provider>
      </Elements>
    </ErrorBoundary>
  );
}

export default App;
