/** @format */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@slices/paymentMethods';
import { generals as generalConstants } from '@constants';
import StripePaymentMethod from '../stripePaymentMethods';
import SquarePaymentMethod from '../squarePaymentMethod';

const PaymentContainer = () => {
  const { selectedOrganization, organizations } = useSelector(
    store => store.app,
  );
  const [paymentOption, setPaymentOption] = useState(null);
  const [paymentOptionloading, setPaymentOptionLoading] = useState(true);
  const dispatch = useDispatch();
  const workforceEnv = process.env.REACT_APP_WORKFORCE_APP === '1';
  useEffect(() => {
    if (organizations && Array.isArray(organizations) && organizations.length) {
      const selectedOrg = organizations[selectedOrganization];
      const { paymentOption } = selectedOrg.organization;
      setPaymentOption(paymentOption);
      if (workforceEnv) {
        setPaymentOptionLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (paymentOptionloading) {
    dispatch(setLoading(generalConstants.PAYMENT_OPTIONS_LOADING));
    if (workforceEnv) {
      return null;
    }
    setPaymentOptionLoading(false);
  }

  return paymentOption === 'STRIPE' ? (
    <StripePaymentMethod />
  ) : (
    <SquarePaymentMethod />
  );
};

export default PaymentContainer;
