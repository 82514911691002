/** @format */

import styled from 'styled-components';
import { device, theme } from '@constants';

export const StyledRadioGroup = styled.section`
  width: 100%;
  flex: 100%;
`;

export const RadioInput = styled.input`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.LIGHTEN_GRAY};
  border-radius: 50%;
  outline: none;

  &:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  &:checked:before {
    background: ${theme.BASE_COLOR};
    border: 1px solid ${theme.BASE_COLOR};
  }
`;

export const LabelStyled = styled.label`
  font-size: 14px;
  color: ${theme.COD_GRAY_LIGHT};
  width: 90%;
  margin-top: ${props => props.marginTopInputLabel ? props.marginTopInputLabel : 0}rem;
`;

export const RadioButton = styled.div`
  display: flex;
  font-size: 20px;
  color: ${theme.BASE_COLOR};
  letter-spacing: 1px;
  flex: 45%;
  input {
    margin: 0px 6px 0px 0px !important;
  }
  align-items: ${props => props.alignItems ? props.alignItems : ''};
`;

export const Title = styled.label`
  font-size: 18px;
  font-weight: bold;
  color: ${theme.COD_GRAY_LIGHT};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: ${props => props.buttonsFlow};
  ${props =>
    props.buttonsFlow === 'row'
      ? ` & > :nth-child(even) {
    margin-left: 20px;
  }`
      : ''}
  margin-top: ${props => props.marginTop}px;
  @media ${device.mediaQuery.MOBILE_SCREEN} {
    flex-flow: column;

    & > :nth-child(even) {
      margin-left: 0px;
    }
  }
`;
