/** @format */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { FullScreen, SignupForm, Modal } from '@components';
import { localStorageKeys } from '@constants';
import {
  setSignupFormState,
  setSignupDOB,
  signup,
  resetAuthState,
  setSignupOrganizationId,
} from '@slices/auth';

const Signup = () => {
  const {
    register,
    trigger,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const [passwordRestricted, setPasswordRestricted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(store => store.auth.signup);
  const { language } = useSelector(store => store.app);
  const { user, largeScreen, signupOrganizations } = useSelector(
    store => store.app,
  );

  useEffect(() => {
    return () => {
      dispatch(resetAuthState());
    };
  }, [dispatch]);

  const onCloseModal = () => {
    history.push('/');
    // TODO: disabling reset auth, its causing a signup with org problem if you close the modal.
    // dispatch(resetAuthState());
  };

  useEffect(() => {
    if (signupOrganizations && signupOrganizations.length === 1) {
      const [organization] = signupOrganizations;
      if (organization.restrictPassword) {
        setPasswordRestricted(true);
      } else {
        setPasswordRestricted(false);
      }
      dispatch(setSignupOrganizationId(organization.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupOrganizations]);

  useEffect(() => {
    const language = localStorage.getItem(localStorageKeys.USER_LANGUAGE);
    if (language && state.formState.language !== language) {
      dispatch(setSignupFormState({ name: 'language', value: language }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOrgSelected = () => {
    if (signupOrganizations) {
      return state.OrganizationUsers[0].organizationId;
    }
    return true;
  };

  const onSubmit = () => {
    if (!isValid || !isOrgSelected()) {
      trigger();
      return;
    }
    dispatch(signup(history));
  };

  const onChangeDay = day => {
    dispatch(
      setSignupDOB({
        key: 'date',
        value: day.value,
      }),
    );
  };

  const onChangeMonth = month => {
    dispatch(
      setSignupDOB({
        key: 'month',
        value: month.value,
      }),
    );
  };

  const onChangeYear = year => {
    dispatch(
      setSignupDOB({
        key: 'year',
        value: year.value,
      }),
    );
  };

  const onChange = async e => {
    const { name, value } = e.target;

    if (name === 'phone' && value.length > 10) {
      return;
    }
    if (
      name === 'phone' &&
      state.formState.phone.length >= 10 &&
      value.length >= 11
    ) {
      return;
    }

    dispatch(
      setSignupFormState({
        name,
        value: name === 'phone' ? value.replace(/[^0-9]/g, '') : value,
      }),
    );

    await trigger(name);
  };

  const onChangeUserOrganization = e => {
    setPasswordRestricted(false);
    const { value } = e.target;
    const orgId = Number(value);
    const org = signupOrganizations.find(o => o.id === orgId);
    sessionStorage.setItem(localStorageKeys.SIGNUP_ORGANIZATION, JSON.stringify(org));
    if (org && org.restrictPassword) {
      setPasswordRestricted(true);
    }
    dispatch(setSignupOrganizationId(orgId));
  };

  const goToLoginPage = () => {
    history.push('/login');
  };

  const onCloseFullScreen = () => {
    history.push('/');
  };

  const onChangeShowPassword = value => {
    dispatch(
      setSignupFormState({
        name: 'showPassword',
        value,
      }),
    );
  };

  const onChangeLanguage = value => {
    dispatch(
      setSignupFormState({
        name: 'language',
        value,
      }),
    );
  };

  const onChangeRiderType = value => {
    dispatch(
      setSignupFormState({
        name: 'riderType',
        value,
      }),
    );
  };

  const signupForm = (
    <SignupForm
      largeScreen={largeScreen}
      language={language}
      state={state.formState}
      loading={state.loading}
      errors={errors}
      validate={register}
      onChange={onChange}
      onSubmit={termsAndConditions => onSubmit(termsAndConditions)}
      isAuthenticated={!!user}
      signupOrganizations={signupOrganizations}
      onCloseModal={onCloseModal}
      onCloseFullScreen={onCloseFullScreen}
      onChangeDay={onChangeDay}
      onChangeMonth={onChangeMonth}
      onChangeYear={onChangeYear}
      goToLoginPage={goToLoginPage}
      onChangeShowPassword={onChangeShowPassword}
      onChangeUserOrganization={onChangeUserOrganization}
      onChangeLanguage={onChangeLanguage}
      onChangeRiderType={onChangeRiderType}
      passwordRestricted={passwordRestricted}
    />
  );

  return largeScreen ? (
    !user && (
      <Portal>
        <Modal width="738" open toggle={onCloseModal}>
          {signupForm}
        </Modal>
      </Portal>
    )
  ) : (
    <FullScreen id="signup-full-screen" headerHeight={0}>
      {signupForm}
    </FullScreen>
  );
};

export default Signup;
