/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { theme } from '@constants';
import {
  ClickHandler,
  Switch as SSwitch,
  UnCheckedText,
  CheckedText,
  Trigger,
  SwitchIcon,
} from './styled';

const Switch = ({
  onChange,
  checked,
  unCheckedText,
  checkedText,
  width,
  height,
  bgColor,
  triggerColor,
  id,
  switchRef,
  showIcon,
  opacity,
  triggerSpacing,
  controlFontSize,
  ...restProps
}) => {
  const onClick = () => {
    onChange(!checked);
  };

  return (
    <ClickHandler onClick={onClick}>
      <SSwitch
        rowRef={switchRef}
        id={id}
        flex={1}
        justify="space-between"
        align="center"
        width={width}
        height={height}
        bgColor={bgColor}
        opacity={opacity}
        {...restProps}
      >
        <UnCheckedText
          id="unchecked-text"
          checked={checked}
          width={width}
          isSpanish={0}
          controlFontSize={controlFontSize}
        >
          {unCheckedText}
        </UnCheckedText>
        <CheckedText id="checked-text" checked={checked} width={width} controlFontSize={controlFontSize}>
          {checkedText}
        </CheckedText>

        <Trigger
          id="trigger"
          checked={checked}
          width={width}
          height={height}
          triggerColor={triggerColor}
          triggerSpacing={triggerSpacing}
        >
          {showIcon && <SwitchIcon icon={faCheck} color={theme.BASE_COLOR} />}
        </Trigger>
      </SSwitch>
    </ClickHandler>
  );
};

Switch.defaultProps = {
  id: null,
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  unCheckedText: PropTypes.string.isRequired,
  checkedText: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  bgColor: PropTypes.string.isRequired,
  triggerColor: PropTypes.string.isRequired,
  id: PropTypes.string,
  switchRef: PropTypes.object,
  showIcon: PropTypes.bool,
  opacity: PropTypes.number,
  triggerSpacing: PropTypes.number,
  controlFontSize: PropTypes.bool.isRequired,
};

Switch.defaultProps = {
  switchRef: null,
  showIcon: false,
  opacity: 1,
  triggerSpacing: 0,
};

export default Switch;
