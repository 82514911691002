/** @format */

import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, Row, Col, Input } from '@components';
import { useLocalStorage } from '@hooks';
import { theme, hints } from '@constants';
import { tripHelpers } from '@utils';
import {
  PickerWrapper,
  TextSmall,
  RdtDatePickerWrapper,
  RdtTimePickerWrapper,
  ValidTimesDropdownWrapper,
  ValidTimesDropdown,
  Tooltip,
} from './styled';

const TripDateTimePicker = ({
  type,
  tripDate,
  tripTime,
  onChange,
  isValidDate,
  validTimes,
  setIsCustomTime,
  onOpenTimePicker,
  checkArriveByPlace,
}) => {
  const { t } = useTranslation();
  const dateInputRef = useRef(null);
  const timeInputRef = useRef(null);
  const [seenHints, setSeenHints] = useLocalStorage('seenHints');

  const [hintsVisibleStep1, hintsVisibleStep2] = useMemo(() => {
    return [
      !seenHints.tripDateTimeFormSmallStep1,
      !seenHints.tripDateTimeFormSmallStep2,
    ];
    // eslint-disable-next-line
  }, []);

  const tripDateTimeFormHints =
    type === 'depart'
      ? hints.tripDepartDateTimeFormSmall
      : hints.tripReturnDateTimeFormSmall;

  useEffect(() => {
    if (!seenHints.tripDateTimeFormSmallStep1) {
      setSeenHints({ ...seenHints, tripDateTimeFormSmallStep1: true });
    } else if (!seenHints.tripDateTimeFormSmallStep2) {
      setSeenHints({ ...seenHints, tripDateTimeFormSmallStep2: true });
    }

    if (hintsVisibleStep1 || hintsVisibleStep2) {
      Tooltip.show(dateInputRef.current);
    }

    return () => {
      Tooltip.hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDate = date => {
    onChange('tripDate', date.format('MM/DD/YYYY'));

    if (hintsVisibleStep1 || hintsVisibleStep2) {
      Tooltip.hide(dateInputRef.current);
      Tooltip.show(timeInputRef.current);
    }
  };

  const onChangeTime = time => {
    setIsCustomTime(true);
    onChange(
      'tripTime',
      tripHelpers.synchronizeMinsAndHours(time, tripTime).format('HH:mm:ss'),
    );

    if (hintsVisibleStep1 || hintsVisibleStep2) {
      Tooltip.hide(timeInputRef.current);
    }
  };

  const onChangeTimeDropdown = selected => {
    setIsCustomTime(false);
    onChange('tripTime', selected.value);

    if (hintsVisibleStep1 || hintsVisibleStep2) {
      Tooltip.hide(timeInputRef.current);
    }
  };

  const today = moment().format('YYYY-MM-DD');

  let formattedTime = tripTime || '';
  if (
    tripTime &&
    (tripTime.toLowerCase().indexOf('am') !== -1 ||
      tripTime.toLowerCase().indexOf('pm') !== -1)
  ) {
    formattedTime = tripTime || '';
  } else {
    formattedTime = tripTime
      ? moment(`${today}T${tripTime}`).format('hh:mm A')
      : '';
  }

  const renderDateInput = (inputProps, openCalendar) => {
    return (
      <Input
        data-cy="date-input"
        inputRef={dateInputRef}
        data-tip={t(tripDateTimeFormHints.date)}
        data-event="custom"
        data-for="trip-date-time-picker-tooltip"
        id="date-input"
        label={inputProps.placeholder}
        inverted
        onFocus={openCalendar}
        value={tripDate}
        readOnly
        paddingX={0}
        autoComplete="off"
      />
    );
  };

  const renderTimeInput = (inputProps, openCalendar) => {
    return (
      <Input
        data-cy="time-input"
        inputRef={timeInputRef}
        data-tip={t(tripDateTimeFormHints.time)}
        data-event="custom"
        data-for="trip-date-time-picker-tooltip"
        id="time-input"
        label={inputProps.placeholder}
        inverted
        onFocus={openCalendar}
        value={formattedTime}
        readOnly
        paddingX={0}
        autoComplete="off"
      />
    );
  };

  const hintsVisible = hintsVisibleStep1 || hintsVisibleStep2;
  return (
    <Col justify="flex-start" spacingV={10} align="center">
      {hintsVisible && (
        <Tooltip
          id="trip-date-time-picker-tooltip"
          backgroundColor={theme.WEIRD_GREEN}
          textColor={theme.WHITE}
          isCapture
          place="bottom"
        />
      )}
      <PickerWrapper>
        <Row justify="space-between" align="flex-start">
          <Col justify="flex-start" align="flex-start" spacingH={6}>
            <TextSmall>{t('trip-date-time-picker.date')}</TextSmall>
            <DateTimePicker
            id="date-time-picker"
              isValidDate={isValidDate}
              placeholder={t('trip-date-time-picker.select-date')}
              dateFormat="ddd, MMM DD"
              renderView={(mode, renderDefault) => (
                <RdtDatePickerWrapper>{renderDefault()}</RdtDatePickerWrapper>
              )}
              renderInput={renderDateInput}
              onChange={onChangeDate}
            />
          </Col>
          <Col justify="flex-start" align="flex-start" spacingH={6}>
            <TextSmall>{t('trip-date-time-picker.time')}</TextSmall>
            {validTimes ? (
              <ValidTimesDropdownWrapper
                id="valid-time-dropdown-wrapper"
                ref={timeInputRef}
                data-tip={t(tripDateTimeFormHints.time)}
                data-event="custom"
                data-for="trip-date-time-picker-tooltip"
              >
                <ValidTimesDropdown
                  data-cy="time-dropdown"
                  className={`valid-${type}-times`}
                  options={validTimes.values}
                  onChange={onChangeTimeDropdown}
                  placeholder={validTimes.placeholder}
                  color={theme.BASE_COLOR}
                  value={formattedTime}
                  inverted
                  rounded
                  bordered
                />
              </ValidTimesDropdownWrapper>
            ) : (
              <DateTimePicker
                onChange={onChangeTime}
                {...(tripTime ? {} : { onOpen: onOpenTimePicker })}
                placeholder={
                  checkArriveByPlace()
                    ? t('trip-date-time-picker.arrive-at')
                    : t('trip-date-time-picker.pickup-at')
                }
                value={formattedTime}
                timeFormat="hh:mm A"
                renderView={(mode, renderDefault) => (
                  <RdtTimePickerWrapper>{renderDefault()}</RdtTimePickerWrapper>
                )}
                renderInput={renderTimeInput}
              />
            )}
          </Col>
        </Row>
      </PickerWrapper>
    </Col>
  );
};

TripDateTimePicker.propTypes = {
  type: PropTypes.oneOf(['depart', 'return']).isRequired,
  tripDate: PropTypes.string.isRequired,
  tripTime: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
  setIsCustomTime: PropTypes.func.isRequired,
  validTimes: PropTypes.object,
  onOpenTimePicker: PropTypes.func.isRequired,
  checkArriveByPlace: PropTypes.func.isRequired,
};

TripDateTimePicker.defaultProps = {
  validTimes: null,
};

export { TripDateTimePicker };
