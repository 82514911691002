/** @format */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  RidershipBonusLanding as CRidershipBonusLanding,
} from '@components';
import { trackAbEvent } from '@slices/app';
import { saveEnrollToRidershipBonus } from '@slices/ridershipBonus';
import { analytics } from '@constants';
import { getDecoratedTiers } from './config';

const RidershipBonus = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    app: { largeScreen, organizations, selectedOrganization },
    ridershipBonus: { loading },
  } = useSelector(store => store);
  const tiers =
    (Array.isArray(organizations) &&
    selectedOrganization !== null &&
    selectedOrganization < organizations.length)
      ? organizations[selectedOrganization]?.detail?.ridershipBonus?.tiers
      : [];

  useEffect(() => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.RIDERSHIP_BONUS_INTRO_OPENED,
        subJourneyName: 'Ridership Bonus',
        journeyName: 'Rider Experience',
      }),
    );
  }, [dispatch]);

  const onClose = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.RIDERSHIP_BONUS_INTRO_CLOSE_WITHOUT_ENROLL,
        subJourneyName: 'Ridership Bonus',
        journeyName: 'Rider Experience',
      }),
    );
    history.push('/');
  };

  const onClickStartEarning = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.RIDERSHIP_BONUS_START_EARNING_CLICKED,
        subJourneyName: 'Ridership Bonus',
        journeyName: 'Rider Experience',
      }),
    );
    dispatch(
      saveEnrollToRidershipBonus(
        (organizations || [])[selectedOrganization]?.organizationId,
        history,
      ),
    );
  };
  return (
    <CRidershipBonusLanding
      ridershipTiers={getDecoratedTiers(tiers)}
      onClickStartEarning={onClickStartEarning}
      loading={loading}
      onClose={onClose}
      onSkip={onClose}
      isLargeScreen={largeScreen}
      organizationName={(organizations || [])[selectedOrganization]?.organization?.name}
    />
  );
};
export default RidershipBonus;
