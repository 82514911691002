import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getSquareUpCards,
  createNewSquareCard,
  setDeletePaymentMethodId,
  deleteSquareCard,
  setSelectedPaymentMethod,
} from '@slices/paymentMethods';
import { trackAbEvent } from '@slices/app';
import { analytics } from '@constants';

import { FullScreen } from '@components';
import { useHeaderHeight, useConfirmationDialog } from '@hooks';
import { helpers } from '@utils';
import PaymentForm from '../../components/squarePaymentMethod';

const SquarePaymentMethod = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.PAYMENT_METHOD_VIEW,
        subJourneyName: 'Payments',
        journeyName: 'Rider Experience',
      }),
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getSquareUpCards());
  }, [dispatch]);
  const { largeScreen } = useSelector(store => store.app);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [headerHeight] = useHeaderHeight();

  const { selectedOrganization, organizations } = useSelector(
    store => store.app,
  );
  const { loading, addedSquareCards, deletePaymentMethodId } = useSelector(
    store => store.paymentMethods,
  );
  const { ConfirmationDialog, openConfirmationDialog } =
    useConfirmationDialog();

  const isPreviousScreenTripOverview = useMemo(
    () =>
      helpers.getObjectProperty(
        location,
        'state.isPreviousScreenTripRequest',
        false,
      ),
    [location],
  );

  const createSquareCard = payload => {
    dispatch(createNewSquareCard(payload));
    if (location.directedFrom === 'load-credits') {
      history.push('load-credits');
    }
  };

  const deleteSelectedSquareCard = () => {
    openConfirmationDialog(false);
    dispatch(deleteSquareCard());
  };

  const openConfirmDeleteModal = () => {
    openConfirmationDialog(true, { onConfirmClick: deleteSelectedSquareCard });
  };

  const onChangeDeletePaymentMethodId = cardId => {
    dispatch(setDeletePaymentMethodId(cardId));
  };

  const goBack = () => {
    history.goBack();
  };

  const goToTripOverview = () => {
    if (isPreviousScreenTripOverview) {
      history.push('/trip-overview');
    }
  };

  const setSelected = selectedPaymentMethod => {
    const { id: paymentMethodId } = addedSquareCards[selectedPaymentMethod];
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.SELECTED_PAYMENT_METHOD,
        subJourneyName: 'Payments',
        journeyName: 'Rider Experience',
        details: {
          paymentMethodId,
          squareUp: true,
        },
      }),
    );
    dispatch(setSelectedPaymentMethod(selectedPaymentMethod));
    goToTripOverview();
  };

  const squarePaymentForm = (
    <PaymentForm
      loading={loading}
      addedPaymentMethods={addedSquareCards || []}
      createSquareCard={createSquareCard}
      deleteSelectedSquareCard={deleteSelectedSquareCard}
      onChangeDeletePaymentMethodId={onChangeDeletePaymentMethodId}
      openConfirmationDialog={openConfirmDeleteModal}
      goBack={goBack}
      setSelected={setSelected}
      deletePaymentMethodId={deletePaymentMethodId}
      selectedOrganization={(organizations || [])[selectedOrganization]?.detail}
      t={t}
    />
  );

  return largeScreen ? (
    <>
      {squarePaymentForm}
      <ConfirmationDialog />
    </>
  ) : (
    <FullScreen id="payment-methods-full-screen" headerHeight={headerHeight}>
      <>
        {squarePaymentForm}
        <ConfirmationDialog />
      </>
    </FullScreen>
  );
};

export default SquarePaymentMethod;
