/** @format */

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import debounce from 'lodash.debounce';

import {
  TripRequestFormLarge,
  TripRequestFormSmall,
  TripDateTimeForm,
  SheetModal,
} from '@components';
import {
  setSearchQuery,
  setSearchQueryTripDate,
  setSearchQueryTripTime,
  setSearchQueryCustomTime,
  fetchAddresses,
  searchTrips,
  resetSearchAddresses,
} from '@slices/tripRequest';
import { trackAbEvent, setShowOrgPlaceMarkerSlider } from '@slices/app';
import { toast, tripHelpers, placeHelpers } from '@utils';
import { generals as generalConstants, analytics } from '@constants';
import { useValidTimes } from '@hooks';
import { useTranslation } from 'react-i18next';

const TripRequest = () => {
  const { t } = useTranslation();
  const [tripDateTimeForm, setTripDateTimeForm] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [tripRequestAvailableDays, setTrAvailableDays] = useState(null);
  const [tripRequestDisabledDates, setTrDisabledDates] = useState(null);
  const [getValidTimes] = useValidTimes();
  const dispatch = useDispatch();
  const history = useHistory();

  const { largeScreen, user, selectedOrganization, organizations, language } =
    useSelector(state => state.app);
  const { search, loading } = useSelector(state => state.tripRequest);

  const pushToLogin = () => {
    history.push('/login');
  };

  if (!largeScreen && !user) {
    pushToLogin();
  }

  useEffect(() => {
    if (
      organizations &&
      Array.isArray(organizations) &&
      (selectedOrganization || selectedOrganization === 0)
    ) {
      const { detail } = organizations[selectedOrganization] || {};
      const {
        polygonRestriction,
        polygon: polygonShape,
        tripRequestAvailableDays,
        tripRequestDisabledDates,
      } = detail || {};

      if (polygonRestriction && polygonShape) {
        setPolygon(polygonShape);
      } else {
        setPolygon(null);
      }

      if (tripRequestAvailableDays) {
        setTrAvailableDays(tripRequestAvailableDays);
      }

      if (tripRequestDisabledDates) {
        setTrDisabledDates(tripRequestDisabledDates);
      }
    } else {
      setPolygon(null);
    }
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.TRIP_REQUEST_VIEW,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
      }),
    );
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchAddresses = useCallback(
    debounce((key, value) => {
      dispatch(fetchAddresses(key, value));
    }, 500),
    [],
  );

  const getAddresses = (key, value) => {
    debounceFetchAddresses(key, value);
  };

  const getPickupAddresses = () => {
    getAddresses(
      'pickup',
      search.query.pickupAddress.label || search.query.pickupAddress,
    );
  };

  const getDestinationAddresses = () => {
    getAddresses(
      'destination',
      search.query.destinationAddress.label || search.query.destinationAddress,
    );
  };

  const clearAddresses = () => {
    dispatch(resetSearchAddresses());
  };

  const onSubmit = () => {
    dispatch(searchTrips(history));
  };

  const onChange = e => {
    const { name, value } = e.target;

    dispatch(
      setSearchQuery({
        key: name,
        value,
      }),
    );

    if (name === 'pickupAddress') {
      getAddresses('pickup', value);
    } else {
      getAddresses('destination', value);
    }
  };

  const onIsRoundTripChange = checked => {
    dispatch(
      setSearchQuery({
        key: 'isReturn',
        value: checked,
      }),
    );
  };

  const onAddressClick = (key, selected) => {
    const address = tripHelpers.getAddressWithTimeZone(
      search[`${key}Addresses`][selected],
    );

    const { lat, lng, label, orgPlaceId } = address || {};
    if (lat && lng && !!polygon && !orgPlaceId) {
      const insidePolygon = tripHelpers.pointInPolygon([parseFloat(lat), parseFloat(lng)], polygon);

      if (!insidePolygon) {
        toast.show('error', `The selected address "${label}" is outside the defined region.`);
        return;
      }
    }

    const otherAddress =
      key === 'pickup'
        ? search.query.destinationAddress
        : search.query.pickupAddress;

    // if other address is defined, check for same addresses
    if (otherAddress?.lat) {
      const isSame = placeHelpers.areSamePlaces(address, otherAddress);

      if (isSame) {
        toast.show('error', t('trip-request.messages.same-location-invalid'));
        return;
      }
    }

    dispatch(
      setSearchQuery({
        key: `${key}Address`,
        value: address,
      }),
    );

    clearAddresses();
  };

  const onPickupAddressClick = selected => {
    onAddressClick('pickup', selected);
  };

  const onDestinationAddressClick = selected => {
    onAddressClick('destination', selected);
  };

  const openTripDateTimeForm = e => {
    const { name } = e.target;
    setTripDateTimeForm({ type: name });
  };

  const closeTripDateTimeForm = () => setTripDateTimeForm(null);

  const onTripDateTimeFormSubmit = data => {
    if (data.tripTime && data.tripDate) {
      if (tripDateTimeForm.type === 'return' && search.query.departDate && search.query.departTime) {
        const departDateTime = `${search.query.departDate} ${search.query.departTime}`;
        const returnDateTime = `${data.tripDate} ${data.tripTime}`;

        if (new Date(departDateTime) > new Date(returnDateTime)) {
          toast.show('error', t('trip-request.messages.return-date-invalid'));
          return;
        }
      }

      if (tripDateTimeForm.type === 'depart' && search.query.returnDate && search.query.returnTime) {
        const departDateTime = `${data.tripDate} ${data.tripTime}`;
        const returnDateTime = `${search.query.returnDate} ${search.query.returnTime}`;

        if (new Date(departDateTime) > new Date(returnDateTime)) {
          toast.show('error', t('trip-request.messages.return-date-invalid'));
          return;
        }
      }

      dispatch(
        setSearchQueryTripDate({
          type: tripDateTimeForm.type,
          data: {
            tripDate: data.tripDate,
          },
        }),
      );

      onSelectTripTime(tripDateTimeForm.type, data.tripTime, data.isCustomTime);
      closeTripDateTimeForm();
    }
  };

  const onSelectTripDate = (type, date) => {
    dispatch(
      setSearchQueryTripTime({
        type,
        data: {
          tripTime: '',
        },
      }),
    );

    const formattedDate = date.format('MM/DD/YYYY');

    if (
      (type === 'return' &&
        search.query.departDate &&
        new Date(search.query.departDate) > new Date(formattedDate)) ||
      (type === 'depart' &&
        search.query.returnDate &&
        new Date(search.query.returnDate) < new Date(formattedDate))
    ) {
      toast.show('error', t('trip-request.messages.return-date-invalid'));
      return;
    }

    dispatch(
      setSearchQueryTripDate({
        type,
        data: {
          tripDate: formattedDate,
        },
      }),
    );
  };

  const goToHome = () => {
    history.push('/');
  };

  const isValidDate = (current, type) => {
    const yesterday = moment().subtract(1, 'day');

    const key = generalConstants.DATE_TO_ADDRESS_MAPPING[type];
    let address = search.query[`${key}Address`];
    let { availableDays } = address;

    const { pickupAddress, destinationAddress } = search.query;

    if (tripRequestDisabledDates?.length > 0 && tripRequestDisabledDates.includes(current.format('MM/DD/YYYY'))) {
      return false;
    }

    if (
      key === 'pickup' &&
      placeHelpers.isAirportOrTerminal(destinationAddress)
    ) {
      address = destinationAddress;
      availableDays = address.availableDaysArriveBy;
    }

    if (
      key === 'destination' &&
      placeHelpers.isAirportOrTerminal(pickupAddress)
    ) {
      address = pickupAddress;
      availableDays = address.availableDaysArriveBy;
    }

    if (
      address?.organizationId &&
      (!availableDays || availableDays?.length === 0)
    ) {
      return false;
    }

    if (address && address.organizationId) {
      return (
        current.isAfter(yesterday) &&
        availableDays.indexOf(generalConstants.DAYS[current.day()]) !== -1
      );
    }

    if (tripRequestAvailableDays) {
      return (
        current.isAfter(yesterday) &&
        tripRequestAvailableDays[generalConstants.DAYS[current.day()]]
      );
    }

    return current.isAfter(yesterday);
  };

  const checkArriveByPlace = type => {
    const key = generalConstants.DATE_TO_ADDRESS_MAPPING[type];
    const { pickupAddress, destinationAddress } = search.query;

    return (
      (key === 'pickup' &&
        placeHelpers.isAirportOrTerminal(destinationAddress, false)) ||
      (key === 'destination' &&
        placeHelpers.isAirportOrTerminal(pickupAddress, false))
    );
  };

  const {
    departDate,
    departTime,
    pickupAddress,
    returnDate,
    returnTime,
    destinationAddress,
    isReturn,
  } = search.query;

  const onSelectTripTime = (type, time, customTime = false, isOpen = false) => {
    const format = 'hh:mm A';
    let updatedTime = moment(time, format);
    const existingTime = moment(search.query[`${type}Time`], format);
    const key = generalConstants.DATE_TO_ADDRESS_MAPPING[type];
    const address = search.query[`${key}Address`];
    const date = search.query[`${type}Date`];

    if (tripRequestAvailableDays && !address.organizationId && date) {
      const day = moment(date).locale('en').format('dddd').toLowerCase();
      const availableTime = tripRequestAvailableDays[day];
      const availableFromTime = moment(availableTime.from, format);
      const availableToTime = moment(availableTime.to, format);

      if (
        updatedTime.isBefore(availableFromTime) ||
        updatedTime.isAfter(availableToTime)
      ) {
        updatedTime = isOpen ? availableFromTime : existingTime;
      }
    }

    let formmatedTime = updatedTime.format(format);

    if (type === 'return' && search.query.departDate && search.query.departTime && search.query.returnDate) {
      const departDateTime = `${search.query.departDate} ${search.query.departTime}`;
      const returnDateTime = `${search.query.returnDate} ${formmatedTime}`;

      if (new Date(departDateTime) > new Date(returnDateTime)) {
        toast.show('error', t('trip-request.messages.return-date-invalid'));
        formmatedTime = search.query.departTime;
      }
    }

    if (type === 'depart' && search.query.departDate && search.query.returnDate && search.query.returnTime) {
      const departDateTime = `${search.query.departDate} ${formmatedTime}`;
      const returnDateTime = `${search.query.returnDate} ${search.query.returnTime}`;

      if (new Date(departDateTime) > new Date(returnDateTime)) {
        toast.show('error', t('trip-request.messages.return-date-invalid'));
        formmatedTime = null;
      }
    }

    dispatch(
      setSearchQueryTripTime({
        type,
        data: {
          tripTime: formmatedTime,
        },
      }),
    );
    dispatch(setSearchQueryCustomTime(customTime));
  };

  const onOpenPicker = type => {
    const time = moment(`${moment().format('YYYY/MM/DD HH')}:00:00`);
    if (type === 'depart') {
      onSelectTripTime(type, time, false, true);
      return;
    }
    const dTime = departTime && moment(`${moment().format('YYYY/MM/DD')} ${departTime}`);
    if (!time.isAfter(dTime) && departTime) {
      onSelectTripTime(type, dTime, false, true);
    } else {
      onSelectTripTime(type, time, false, true);
    }
  };

  const getAddressFromType = type => {
    const key = generalConstants.DATE_TO_ADDRESS_MAPPING[type];
    return search.query[`${key}Address`];
  };

  const disabled =
    !departDate ||
    !departTime ||
    !pickupAddress ||
    typeof pickupAddress === 'string' ||
    !destinationAddress ||
    typeof destinationAddress === 'string' ||
    (isReturn && (!returnDate || !returnTime));

  const onClear = key => {
    dispatch(
      setSearchQuery({
        key,
        value: '',
      }),
    );
    clearAddresses();
  };

  useEffect(() => {
    if (!largeScreen) {
      dispatch(setShowOrgPlaceMarkerSlider(false));
      return () => {
        dispatch(setShowOrgPlaceMarkerSlider(true));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    state: { ...search, loading },
    onChange,
    onIsRoundTripChange,
    getPickupAddresses,
    getDestinationAddresses,
    clearAddresses,
    disabled,
    onPickupAddressClick,
    onDestinationAddressClick,
    openTripDateTimeForm,
    onSubmit,
    onSelectTripTime,
    onSelectTripDate,
    goToHome,
    getValidTimes,
    checkArriveByPlace,
    onClear,
    pushToLogin,
    loggedIn: !!user,
    language,
  };

  const tripRequestForm = largeScreen ? (
    <TripRequestFormLarge
      {...props}
      isValidDate={isValidDate}
      onOpenPicker={onOpenPicker}
    />
  ) : (
    <SheetModal>
      {!tripDateTimeForm ? (
        <TripRequestFormSmall {...props} />
      ) : (
        <TripDateTimeForm
          key="trip-dt"
          tripDate={search.query[`${tripDateTimeForm.type}Date`]}
          tripTime={search.query[`${tripDateTimeForm.type}Time`]}
          onBack={closeTripDateTimeForm}
          onSet={onTripDateTimeFormSubmit}
          type={tripDateTimeForm.type}
          isValidDate={isValidDate}
          getValidTimes={getValidTimes}
          tripRequestAvailableDays={tripRequestAvailableDays}
          getAddressFromType={getAddressFromType}
          checkArriveByPlace={checkArriveByPlace}
        />
      )}
    </SheetModal>
  );

  return tripRequestForm;
};

export default TripRequest;
