/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { helpers, userHelpers } from '@utils';
import { theme, generals as generalConstants } from '@constants';
import {
  Row,
  Col,
  TripPathDetails,
  ReactAddToCalendar,
  SelectedPaymentMethod,
  PromoCode,
  FreeRidePoints,
} from '..';
import {
  TripDetailCol,
  Title,
  TripTimeTitle,
  TripTimeValue,
  TripDetailsRow,
  StyledButton,
  BackButton,
  TextSmall,
  ImageRounded,
  ImageCol,
  RouteInfoRow,
  TripNotFoundMessage,
  LoaderIcon,
  LoaderWrapperRow,
  CalenderCol,
  MinimizableCol,
  AdditionalInfoTitle,
  AdditionalInfoValue,
} from './styled';

export const TripDetail = ({
  trip,
  loading,
  goBack,
  openConfirmCancelModal,
  selectTransitShareTrip,
  payingOrganization,
  minimized,
  toggleMinimized,
  largeScreen,
  addToCalenderBtnCliked,
  calendarItemClicked,
  user,
  isPasswordless,
  checkIfCurrentTransitShareSelected,
}) => {
  const { t } = useTranslation();
  const getAddToCalendarBusEvent = () => {
    const [pickup, destination] = trip.places;
    const event = {
      title: `${trip.operator || 'Transit'} Upcoming Trip`,
      description: `
        From: ${pickup.address}
        To: ${destination.address}
      `,
      location: pickup.address,
      startTime: trip.leaveTime,
      endTime: trip.arriveTime,
    };

    return event;
  };

  if (!trip) {
    return (
      <TripDetailCol
        justify="flex-start"
        align="flex-start"
        spacingV={30}
        spacingH={30}
      >
        {loading === generalConstants.MY_TRIP_LOADING ? (
          <LoaderWrapperRow
            id="trip-detail-loader"
            justify="center"
            align="center"
            spacingV={30}
          >
            <LoaderIcon icon={faSpinner} spin />
          </LoaderWrapperRow>
        ) : (
          <TripNotFoundMessage id="trip-not-found-message">
            {t('trip-detail.labels.trip-not-found')}
          </TripNotFoundMessage>
        )}
      </TripDetailCol>
    );
  }

  const getTotalPricing = () => ({
    fare: parseFloat(trip.pricing.fare),
    tax: parseFloat(trip.pricing.tax),
    fees: parseFloat(trip.pricing.fees),
    credits: parseFloat(trip.pricing.credits),
    discount: parseFloat(trip.pricing.discount),
    total: parseFloat(trip.pricing.total),
  });

  const showCancelButton = ['Requested', 'Scheduled'].includes(trip.status);
  const paymentEnabled = trip.cost === 'BUY' && trip.pricing && !trip.pointDetails;

  const actionButtons = (
    <Row flex="initial" justify="space-between" align="center">
      <BackButton
        id="go-back-btn"
        onClick={goBack}
        label={t('trip-detail.buttons.back')}
        color={theme.BASE_COLOR}
        inverted
        bordered
        fullWidth={showCancelButton ? 0 : 1}
      />
      {showCancelButton && (
        <StyledButton
          id="cancel-trip-btn"
          data-cy="cancel-ride-btn"
          onClick={openConfirmCancelModal}
          label={t('trip-detail.buttons.cancel')}
          color={theme.BASE_COLOR}
          icon={
            loading === generalConstants.CANCEL_TRIP_LOADING ? faSpinner : null
          }
          iconProps={{ spin: true }}
        />
      )}
    </Row>
  );

  const getLabelForButton = () => {
    if (checkIfCurrentTransitShareSelected()) {
      return t('trip-detail.buttons.remove-trip');
    }
    return t('trip-detail.buttons.add-trip');
  };

  const renderButtons = () => {
    if (trip.type === 'transit' && !trip.status) {
      return (
        <Row flex="initial" justify="flex-start" align="center">
          <BackButton
            id="go-back-btn"
            onClick={goBack}
            label={t('trip-detail.buttons.back')}
            color={theme.BASE_COLOR}
            inverted
            bordered
            fullWidth={0}
          />
          <CalenderCol
            align="flex-start"
            justify="flex-start"
            onClick={!largeScreen && minimized ? toggleMinimized : () => {}}
          >
            <ReactAddToCalendar
              event={getAddToCalendarBusEvent()}
              buttonLabel={t('trip-detail.buttons.add-to-calendar')}
              buttonClicked={addToCalenderBtnCliked}
              calendarItemClicked={calendarItemClicked}
            />
          </CalenderCol>
        </Row>
      );
    }

    if (trip.type === 'transit_share' && !trip.status) {
      return (
        <Row flex="initial" justify="flex-start" align="center">
          <BackButton
            id="go-back-btn"
            onClick={goBack}
            label={t('trip-detail.buttons.back')}
            color={theme.BASE_COLOR}
            inverted
            bordered
            fullWidth={0}
          />
          <StyledButton
            id="add-trip-btn"
            onClick={selectTransitShareTrip}
            label={getLabelForButton()}
            color={theme.BASE_COLOR}
          />
        </Row>
      );
    }

    return actionButtons;
  };

  if (!trip || !trip.places) {
    return null;
  }

  const [pickup, destination] = trip.places;

  const driverContact = userHelpers.getDriverContact(user, trip);

  const getIsPaymentFailed = trip => {
    const status = trip?.paymentDetail?.status;

    if (
      status === generalConstants.PAYMENT_STATUS.intentCreationFailed ||
      status === generalConstants.PAYMENT_STATUS.intentCaptureFailed
    ) {
      return true;
    }

    return false;
  };

  return (
    <TripDetailCol
      justify="space-between"
      align="center"
      spacingV={30}
      spacingH={30}
    >
      <Col justify="flex-start" align="center">
        <Row
          flex="initial"
          justify="space-between"
          align="center"
          spacingV={10}
        >
          <Title>{t('trip-detail.labels.trip-details')}</Title>

          <Col
            flex="initial"
            justify="flex-start"
            align="flex-start"
            fullWidth={false}
          >
            <TripTimeTitle>{t('trip-detail.labels.trip-time')}</TripTimeTitle>
            <TripTimeValue>{trip.tripTime}</TripTimeValue>
          </Col>
        </Row>

        <MinimizableCol
          justify="flex-start"
          align="center"
          minimized={minimized ? 1 : 0}
        >
          {(trip?.route?.driver || trip?.route?.vehicle) && (
            <RouteInfoRow
              flex="initial"
              justify="flex-start"
              align="flex-start"
              spacingV={15}
            >
              {(trip.route.driver || driverContact) && (
                <Col justify="flex-start" align="flex-start">
                  <Title>{t('trip-detail.labels.driver')}</Title>
                  <Row
                    flex="initial"
                    justify="flex-start"
                    align="center"
                    spacingV={10}
                  >
                    {trip.route.driver.photo && (
                      <ImageCol justify="flex-start" align="flex-start">
                        <ImageRounded src={trip.route.driver.photo} />
                      </ImageCol>
                    )}
                    <Col justify="flex-start" align="flex-start" spacingH={8}>
                      {trip.route.driver.name && (
                        <TextSmall>{trip.route.driver.name}</TextSmall>
                      )}
                      {driverContact && (
                        <TextSmall>
                          <a href={`tel:${driverContact}`}>{driverContact}</a>
                        </TextSmall>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
              {trip.route.vehicle && trip.route.vehicle.detail && (
                <Col justify="flex-start" align="flex-start">
                  <Title>{t('trip-detail.labels.vehicle')}</Title>
                  <Row
                    flex="initial"
                    justify="flex-start"
                    align="center"
                    spacingV={10}
                  >
                    {trip.route.vehicle.photo && (
                      <ImageCol justify="flex-start" align="flex-start">
                        <ImageRounded src={trip.route.vehicle.photo} />
                      </ImageCol>
                    )}
                    {trip.route.vehicle.detail && (
                      <Col justify="flex-start" align="flex-start" spacingH={8}>
                        <TextSmall>
                          {trip.route.vehicle.detail.make &&
                            `${trip.route.vehicle.detail.make} `}
                          {trip.route.vehicle.detail.model &&
                            ` ${trip.route.vehicle.detail.model}`}
                        </TextSmall>
                        <TextSmall weight="normal">
                          {trip.route.vehicle.detail.color &&
                            `${trip.route.vehicle.detail.color}`}
                          {trip.route.vehicle.detail.name &&
                            ` - ${trip.route.vehicle.detail.name}`}
                        </TextSmall>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
            </RouteInfoRow>
          )}
          <TripDetailsRow
            flex="initial"
            justify="flex-start"
            align="center"
            spacingV={10}
          >
            <TripPathDetails
              tripDetails={trip}
              pickupAddress={helpers.getStreetAddress(pickup.address)}
              destinationAddress={helpers.getStreetAddress(destination.address)}
              payingOrganization={
                trip.cost === 'FREE' && !trip.pointDetails
                  ? payingOrganization
                  : null
              }
              freeRidePoints={trip.freeRidePoints}
              freeRidePointsApplied={trip.freeRidePoints !== null}
            />
          </TripDetailsRow>
          {trip.freeRidePoints !== null &&
            trip.freeRidePoints !== undefined && (
              <FreeRidePoints points={trip.freeRidePoints} />
            )}
          {trip.pointDetails && trip.pointDetails.pointPerRide > -1 ? (
            <Row flex="initial" align="center" spacingV={10}>
              <AdditionalInfoTitle>
                {t('trip-detail.labels.points-used')}:{' '}
              </AdditionalInfoTitle>
              &nbsp;
              <AdditionalInfoValue>
                {trip.pointDetails.pointPerRide}
              </AdditionalInfoValue>
            </Row>
          ) : null}
          {trip.additionalRiders?.ambulatory ? (
            <Row
              flex="initial"
              align="center"
              justify="flex-start"
              spacingV={10}
            >
              <AdditionalInfoTitle>
                {t('trip-detail.labels.additional-riders.ambulatory')}:{' '}
              </AdditionalInfoTitle>
              &nbsp;
              <AdditionalInfoValue>
                {trip.additionalRiders.ambulatory}
              </AdditionalInfoValue>
            </Row>
          ) : null}
          {trip.additionalRiders?.handicapped ? (
            <Row flex="initial" align="center" spacingV={10}>
              <AdditionalInfoTitle>
                {t('trip-detail.labels.additional-riders.handicapped')}:{' '}
              </AdditionalInfoTitle>
              &nbsp;
              <AdditionalInfoValue>
                {trip.additionalRiders.handicapped}
              </AdditionalInfoValue>
            </Row>
          ) : null}
          {trip.specialNeeds && trip.specialNeeds.length ? (
            <Row flex="initial" align="center" spacingV={10}>
              <AdditionalInfoTitle>
                {t('trip-detail.labels.special-needs')}:{' '}
              </AdditionalInfoTitle>
              &nbsp;
              <AdditionalInfoValue>
                {trip.specialNeeds
                  .map(x =>
                    t(
                      `special-needs.options.${x
                        .toLowerCase()
                        .split(' ')
                        .join('-')}`,
                    ),
                  )
                  .join(', ')}
              </AdditionalInfoValue>
            </Row>
          ) : null}

          {getIsPaymentFailed(trip) ? (
            <Row flex="initial" align="center" spacingV={10}>
              <AdditionalInfoTitle color={theme.TOMATO}>
                {t('trip-detail.labels.payment-status.label')}:{' '}
              </AdditionalInfoTitle>
              &nbsp;
              <AdditionalInfoValue color={theme.TOMATO}>
                {t('trip-detail.labels.payment-status.value')}
              </AdditionalInfoValue>
            </Row>
          ) : null}

          {paymentEnabled && (
            <SelectedPaymentMethod
              cost={getTotalPricing()}
              card={helpers.getObjectProperty(trip, 'paymentDetail.card', {})}
              clickDisabled
              selectedSelfPaymentOption={
                (helpers.getObjectProperty(trip, 'paymentDetail.acceptCash', {}) &&
                generalConstants.SELF_PAYMENT_OPTIONS.CASH) ||
                (helpers.getObjectProperty(trip, 'paymentDetail.card', {}) &&
                generalConstants.SELF_PAYMENT_OPTIONS.CARD) ||
                null
              }
            />
          )}

          {trip.promoCode && (
            <PromoCode
              code={helpers.getObjectProperty(trip, 'promoCode', '')}
            />
          )}
        </MinimizableCol>
      </Col>

      {!isPasswordless && renderButtons()}
    </TripDetailCol>
  );
};

TripDetail.propTypes = {
  goBack: PropTypes.func.isRequired,
  trip: PropTypes.object,
  loading: PropTypes.string,
  openConfirmCancelModal: PropTypes.func.isRequired,
  minimized: PropTypes.bool.isRequired,
  selectTransitShareTrip: PropTypes.func.isRequired,
  addToCalenderBtnCliked: PropTypes.func,
  calendarItemClicked: PropTypes.func,
  payingOrganization: PropTypes.string,
  toggleMinimized: PropTypes.func.isRequired,
  largeScreen: PropTypes.bool.isRequired,
  user: PropTypes.object,
  isPasswordless: PropTypes.bool,
  checkIfCurrentTransitShareSelected: PropTypes.func,
};

TripDetail.defaultProps = {
  payingOrganization: null,
  trip: null,
  loading: null,
  addToCalenderBtnCliked: null,
  calendarItemClicked: null,
  user: {},
  isPasswordless: false,
  checkIfCurrentTransitShareSelected: null,
};

export default TripDetail;
